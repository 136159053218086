import { useNavigate, useLocation } from "react-router-dom";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Avatar } from '@mui/material';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import * as React from 'react';

import { pageLinks, isPageAllowed } from "../../utils/Constants";
import useLocalStorage from "../../hooks/useLocalStorage";
import { EventConsumer } from "../../contexts/events";
import AuthConsumer from "../../contexts/auth";
import logo from '../Images/Tralexho-O.svg';

export default function MenuAppBar() {
  const { user, logout } = AuthConsumer();
  const { currentEvent } = EventConsumer();
  const [anchorElUserMenu, setAnchorElUserMenu] = React.useState(null);
  const [anchorElAppMenu, setAnchorElAppMenu] = React.useState(null);

  const [pageTitle, setPageTitle] = React.useState("");

  const localSelectedAppIndex = parseInt(localStorage.getItem("selectedAppIndex")) || -1;
  const [selectedAppIndex, setSelectedAppIndex] = useLocalStorage("selectedAppIndex", localSelectedAppIndex);

  //routing between pages
  const navigate = useNavigate();
  let location = useLocation();

  // useMemo is used to avoid the dependencies of this useEffect Hook change on every render.
  const pages = React.useMemo(() => pageLinks, []);

  // Update title when the pages are programmatically loaded using navigate function
  React.useEffect(() => {
    // console.log("loading : " + location.pathname + " selectedAppIndex:  " + selectedAppIndex)
    for (let i = 0; i < pages.length; i++) {
      if (pages[i].link === location.pathname) {
        setSelectedAppIndex(i);
        break;
      }
    }
    // eslint-disable-next-line
  }, [location, pages])


  // Handle menu actions
  const handleOpenUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };


  const handleOpenAppMenu = (event) => {
    setAnchorElAppMenu(event.currentTarget);
  };

  const handleCloseAppMenu = () => {
    setAnchorElAppMenu(null);
  };

  React.useEffect(() => {
    if (selectedAppIndex === 0 && currentEvent !== "")
      setPageTitle(currentEvent)
    else
      setPageTitle(selectedAppIndex > -1 ? (pages[selectedAppIndex].name) : (""))

  }, [selectedAppIndex, currentEvent, pages]);

  // Handle logout
  const handleLogout = () => {
    setAnchorElUserMenu(null);
    logout();
  }

  return (
    <React.Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Avatar sx={{ p: 0, mr: 0.5, background: "white" }} src={logo} alt="Logo" onClick={handleOpenAppMenu} />
          {user && (
            <>
              <Menu
                id="menu-appbar"
                sx={{ mt: '45px' }}
                anchorEl={anchorElAppMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElAppMenu)}
                onClose={handleCloseAppMenu}
              >
                {pages.map((page, index) =>
                ((!page.skipMenu && isPageAllowed(user, page.link)) &&
                  <MenuItem
                    key={page.name}
                    selected={index === selectedAppIndex}
                    onClick={() => {
                      setSelectedAppIndex(index);
                      handleCloseAppMenu();
                      navigate(page.link);
                    }}
                  >
                    {page.name}
                  </MenuItem>
                )
                )}
              </Menu>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {pageTitle}
              </Typography>
              {user && (
                <Box sx={{ flexGrow: 0, mr: 1 }}>
                  <div>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenUserMenu}
                      color="inherit"
                      sx={{ p: 1, borderRadius: "50%", border: "2px solid white", width: 45 }}
                    >
                      <Typography variant="body1" component="div">
                        {user ? user.shortName : ""}
                      </Typography>
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      sx={{ mt: '45px' }}
                      anchorEl={anchorElUserMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUserMenu)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </div>
                </Box>
              )}
            </>
          )}
          {!user && (
            <div>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Tralexho
              </Typography>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

