import * as React from "react";
import AuthConsumer from "../../contexts/auth";
import { getImages, getProductInstanceById, searchEvents } from "../../utils/capture";
import { getProductClass } from "../../utils/Constants";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { grey } from '@mui/material/colors';

import utc from 'dayjs/plugin/utc';
import dayjs from "dayjs";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { getLocaleDateTime } from "../../utils/date";
dayjs.extend(utc)



export default function RecentEvents({
    eventType,
    duration = 7,
    noOfEvents = 10
}) {
    const { user, getToken } = AuthConsumer();
    const [events, setEvents] = React.useState([]);
    const [statusText, setStatusText] = React.useState("");
    const [refreshing, setRefreshing] = React.useState(false);

    const getImage = async (authToken, event_id) => {
        const images = await getImages(authToken, { "event_id": event_id })
        if (images.length > 0) {
            return images[0].thumbnail_image_url
        }
        return ""
    }

    const search = async () => {
        setRefreshing(true);
        const searchObj = {
            "search_begin_epoch": dayjs().subtract(duration, 'days').utc().unix(),
            "owning_gln": user.profile.og[0],
            "user_event": eventType,
            "results_limit": noOfEvents
        }
        try {
            const token = await getToken();
            const searchResp = await searchEvents(token, searchObj);

            const tmpResults = [];

            for (const res of searchResp) {
                const image = await getImage(token, res.event_id);
                const products = []
                if (res.output_stock_info.length > 0) {
                    for (const instance of res.output_stock_info) {
                        const instanceObj = {
                            "gtin": instance.gtin,
                            "entity": getProductClass(instance.gtin).entity,
                            "stock_quantity": Math.abs(instance.stock_quantity),
                            "stock_action": instance.stock_quantity > 0 ? "Added": "Deducted",
                            "uom": instance.uom
                        }
                        products.push(instanceObj);
                    }
                } else if (res.output_product_instances !== "") {
                    const instances = await getProductInstanceById(token, {"instance_ids": res.output_product_instances});
                    for (const instance of instances) {
                        const instanceObj = {
                            "gtin": instance.gtin,
                            "entity": getProductClass(instance.gtin).entity,
                            "stock_quantity": Math.abs(instance.quantity),
                            "stock_action": "",
                            "uom": instance.uom
                        }
                        products.push(instanceObj);
                    }
                }

                const obj = {
                    "eventTime": getLocaleDateTime(res.event_start_unix_epoch),
                    "thumbnail": image,
                    "entities": products
                }
                tmpResults.push(obj);
            }
            setEvents(tmpResults);
            setRefreshing(false);
            setStatusText(`Showing recent ${tmpResults.length} events!`)
        } catch (err) {
            console.log(err)
            setEvents([]);
            setRefreshing(false);
            setStatusText(`Unable to retrieve events! Try Again!`)
        }
    }

    return (
        <Stack justifyContent={"center"} alignItems={"center"} spacing={1}>
            {refreshing ?
                <CircularProgress size={36} /> :
                <Button variant={"contained"} onClick={() => search()}>Refresh</Button>
            }
            {
                statusText !== "" &&
                <Typography variant="caption">{statusText}</Typography>
            }
            <ImageList sx={{ width: 335 }} cols={1}>
                {events.map((item, idx) => (
                    <ImageListItem key={idx} sx={{
                        border: "1px solid", m: 1, p: 1,
                        borderColor: grey[300],
                        background: grey[100]
                    }}>
                        {item.thumbnail &&
                            <img
                                src={item.thumbnail}
                                alt={item.entity}
                                loading="lazy"
                                style={{ width: "300px", height: "300px", objectFit: "contain" }}
                            />
                        }
                        <ImageListItemBar
                            title={item.entities.map((instance, kidx) => (
                                <Stack key={kidx} spacing={0.1}>
                                    <Typography variant="body2"> {instance.entity}</Typography>
                                    <Typography variant="caption"> {instance.stock_action} {instance.stock_quantity} {instance.uom}(s)</Typography>
                                    <Typography variant="caption"> {item.eventTime}</Typography>
                                </Stack>
                            ))}
                            position="below"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Stack>
    )
}