import {
  useNavigate,
  useLocation,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import React from "react";
import './App.css';

import { landingUrl, isPageAllowed } from "./utils/Constants";
import { EventProvider } from "./contexts/events";
import NavBar from "./components/Common/NavBar";
import Login from "./components/Common/Login";
import { Events } from "./components/Events";
import AuthConsumer from "./contexts/auth";
import Orders from "./components/Orders";
import Inventory from "./components/Inventory";
import Transactions from "./components/transactions";

// Components

function RequireAuth({ children }) {
  const { user } = AuthConsumer();
  const location = useLocation();

  if (user !== null && isPageAllowed(user, location.pathname)) {
    return children
  }

  if (location.pathname !== "/") {
    return <Navigate
      to="/"
      replace
      state={{ path: location.pathname }}
    />
  }
}

function App() {
  const { user } = AuthConsumer();
  let navigate = useNavigate();
  let location = useLocation();

  React.useEffect(() => {
    if (user !== null && location.pathname === "/") {
      navigate(landingUrl)
    }
  })
  return (
    <div className="App">
      <EventProvider>
        <NavBar />
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/events" element={
            <RequireAuth>
              <Events />
            </RequireAuth>
          } />

          <Route path="/orders" element={
            <RequireAuth>
              <Orders />
            </RequireAuth>
          } />

          <Route path="/inventory" element={
            <RequireAuth>
              <Inventory />
            </RequireAuth>
          } />

          <Route path="/transactions" element={
            <RequireAuth>
              <Transactions />
            </RequireAuth>
          } />
        </Routes>
      </EventProvider>

    </div>
  );
}

export default App;
