import * as React from "react";
import {Button, Stack, Typography } from "@mui/material";
import { OrdersListing } from "../Orders/order_listing";


export default function OrdersFulfillment({ location, eventDetail, onExit }) {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const back = () => {
        onExit()
    }
    
    return (
        <>
        <Stack alignItems="center" spacing={1}>
                <Button variant="contained"  sx={{ width: 100, p: 1, m: 1 }} 
                    onClick={() => back()} 
                    disabled={isSubmitting}
                >
                    <Typography>
                        Back
                    </Typography>
                </Button>
            </Stack>
            <OrdersListing
                orderStatus="Processing"
                allowSelection={true}
                enableFulfillment={true}
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                eventDetail={eventDetail}
                location={location}
                />
            
        </>
    )
}