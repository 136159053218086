import { Button, LinearProgress, Stack, Typography, Box } from "@mui/material";
import * as React from "react";
import AuthConsumer from "../../contexts/auth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { DataGrid } from "@mui/x-data-grid";
import "dayjs/locale/en-in";
import dayjs from "dayjs";

import { getProductClass } from "../../utils/Constants";
import { getOrders, getProductInstanceById } from "../../utils/capture";
import MiniPdfModal from "./MiniPdfViewer";
dayjs.extend(localizedFormat)


const columns = [
    { field: "id", headerName: "Order Id", width: 90 },
    {
        field: "fulfilledOn",
        headerName: "Fulfilled On",
        type: "string",
        width: 100,
        renderCell: (params) => (
            <>
                {dayjs(params.value * 1000).locale('en-in').format("L  h:mm A")}
            </>
        )
    },
    {
        field: "lineItems",
        headerName: "Order Items",
        sortable: false,
        width: 160,
        renderCell: (params) => (
            <Stack alignContent="left">
                {params.value.map((item, idx) => (
                    <div key={idx} style={{
                        textAlign: "left",
                        borderBottom: ((idx < params.value.length - 1) ? "1px solid lightgray" : "none"),
                        textTransform: "capitalize"
                    }}>
                        <Typography variant="caption" >
                            {(item.toLowerCase())}
                        </Typography>
                    </div>
                ))}
            </Stack>
        ),
    },
    {
        field: "shippingAddress",
        headerName: "Customer",
        width: 350,
        align: "left",
        sortable: false,
        renderCell: (params) => (
            <Stack alignContent="left">
                <div style={{ textAlign: "left" }}>
                    <Typography variant="caption">
                        {params.value}
                    </Typography>
                </div>
            </Stack>
        ),
    },
];

export default function OrdersPrint() {
    const { getToken } = AuthConsumer();
    const localSearchStart = dayjs().startOf("day").subtract(1, "days").format("YYYY-MM-DD");
    const [searchStart, setSearchStart] = React.useState(localSearchStart);

    const localSearchEnd = dayjs().endOf("day").format("YYYY-MM-DD");
    const [searchEnd, setSearchEnd] = React.useState(localSearchEnd);
    const [searching, setSearching] = React.useState(false);


    // Data grid
    const [results, setResults] = React.useState([]);
    const [orderSelection, setOrderSelection] = React.useState([]);

    // Printing Data
    const ordersForPrint = React.useRef();
    const [finalPrintOrders, setFinalPrintOrders] = React.useState([])


    const getAddress = (v) => {
        return `${v.customer_name}, ${v.address_1}, ${v.city}, ${v.state} - ${v.pincode}.\n${v.mobile}`
    }

    const search = async () => {
        setSearching(true);
        const searchObj = {
            "start_fulfillment_epoch": dayjs(searchStart).startOf("day").utc().unix(),
            "end_fulfillment_epoch": dayjs(searchEnd).endOf("day").utc().unix(),
            "order_status": "Fulfilled"
        }
        const token = await getToken();
        const searchResp = await getOrders(token, searchObj);
        if (searchResp.length === 0) {
            setSearching(false);
            return;
        }
        const instance_ids = new Set();
        const tmpResults = [];
        for (const order of searchResp) {
            order.order_details["fulfillmentDetails"] = JSON.parse(order.order_details["fulfillmentDetails"])
            for (const item of order.order_details["fulfillmentDetails"].items) {
                instance_ids.add(item.instance_id)
            }
            let row = {
                "id": order.orderId,
                "fulfilledOn": order.order_details.fulfillment_unix_epoch,
                "shippingAddress": getAddress(order.order_details.shippingAddress),
                "lineItems": []
            }
            for (const lineItem of order.order_line_items) {
                const productInfo = getProductClass(lineItem.productId)
                row.lineItems.push(productInfo.entity + " - " + lineItem.quantity)
            }
            tmpResults.push(row)
        }
        setResults(tmpResults);

        const instancesSearchObj = {
            "instance_ids": [...instance_ids].join(",")
        }
        const instancesSearchResp = await getProductInstanceById(token, instancesSearchObj)
        const instanceDetails = {}
        for (const instanceInfo of instancesSearchResp) {
            instanceDetails[instanceInfo.instance_id] = {
                gtin: instanceInfo.gtin,
                packedOn: dayjs(instanceInfo.created_at + "z").locale("en-in").format("L h:mm A"),
                entity: getProductClass(instanceInfo.gtin).entity
            }
        }
        const printItems = {};
        for (const order of searchResp) {
            let totalOrderItems = 0;
            for (const item of order.order_details["fulfillmentDetails"].items) {
                totalOrderItems += item.quantity;
            }
            let currentIdx = 1;
            const itemsToPrint = []
            for (const item of order.order_details["fulfillmentDetails"].items) {
                for (let i = 1; i <= item.quantity; i++, currentIdx++) {
                    itemsToPrint.push({
                        orderId: order.orderId,
                        itemIdx: `${currentIdx} / ${totalOrderItems}`,
                        shippingAddress: order.order_details.shippingAddress,
                        instance_id: item.instance_id,
                        ...instanceDetails[item.instance_id]
                    })
                }
            }
            printItems[order.orderId] = itemsToPrint
        }
        ordersForPrint.current = printItems
        setSearching(false);
    }

    const printOrders = () => {
        const itemsToPrint = []
        for (const orderId of orderSelection) {
            itemsToPrint.push(...ordersForPrint.current[orderId])
        }
        setFinalPrintOrders(itemsToPrint);
    }

    React.useEffect(() => {
        setFinalPrintOrders([])
    }, [orderSelection]);

    return (
        <Stack spacing={2} direction="column" alignItems="center">
            <Stack direction="row" alignItems="center" justifyContent="center"
                sx={{ flexWrap: "wrap", gap: 2 }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <DatePicker label={"Fulfilment Date - From"}
                        value={dayjs(searchStart)}
                        onChange={(newValue) => setSearchStart(newValue.format("YYYY-MM-DD"))}

                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <DatePicker label={"Fulfilment Date - To"}
                        value={dayjs(searchEnd)}
                        onChange={(newValue) => setSearchEnd(newValue.format("YYYY-MM-DD"))}

                    />
                </LocalizationProvider>
                <Button variant="contained" size="large" onClick={() => search()}>
                    <Typography>
                        Search
                    </Typography>
                </Button>
                <Button variant="contained" size="large" 
                    disabled={ (orderSelection.length === 0 )}
                    onClick={() => printOrders()}>
                        <Typography>
                            Prepare Label
                        </Typography>
                    </Button>
                
                {(finalPrintOrders.length > 0 && !searching) &&
                    <MiniPdfModal orderList={finalPrintOrders} />
                }
            </Stack>
            {searching && <LinearProgress sx={{ width: 300 }} />}
            {(results.length > 0) ?
                <Box sx={{ width: "100%", p: 1 }}>
                    <DataGrid
                        sx={{ height: 500 }}
                        rows={results}
                        columns={columns}
                        getRowHeight={() => "auto"}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setOrderSelection(newRowSelectionModel);
                        }}
                        orderSelection={orderSelection}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box> : <Typography variant="body2">
                    No Results Found!
                </Typography>
            }
        </Stack>
    )
}


