import { FormControl, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React from 'react';

import AuthConsumer from '../../contexts/auth';

export default function Login() {
  const { loginWithGoogle, login } = AuthConsumer();
  const [username, setUserName] = React.useState("")
  const [password, setPassword] = React.useState("")

  const signinWithGoogle = () => {
    loginWithGoogle();
  };

  const signin = () => {
    login(username, password);
  };

  return (

    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight="40vh"
      flexDirection="column"
      sx={{ p: 2 }}
    >
      <FormControl>
        <form style={{ display: "flex", flexDirection: "column", margin: 2}}>
          <TextField id="username" label="username" variant="standard" sx={{m:2}}
            aria-required="true" autoComplete="username"
            value={username}
            onChange={(event) => {
              setUserName(event.target.value)
            }}
          />
          <TextField id="password" label="password" type="password" variant="standard" sx={{m:2}}
            aria-required="true" autoComplete="current-password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
          />
          <Button variant="contained" data-testid="signin-button" onClick={() => signin()} sx={{m:2}}>
            <Typography variant="body1">
              Sign In
            </Typography>
          </Button>
        </form>
      </FormControl>
      <Typography variant="body1" sx={{m:2}}>
        or
      </Typography>
      <Button variant="contained" data-testid="signin-button" onClick={() => signinWithGoogle()} sx={{m:2}}>
        <Typography variant="body1">
          Sign In using Traxleho
        </Typography>
      </Button>

    </Box>
  )
}