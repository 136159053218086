import { Typography, Stack, Button, Box, CircularProgress } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';import localizedFormat from "dayjs/plugin/localizedFormat";
import { DataGrid } from "@mui/x-data-grid";
import utc from 'dayjs/plugin/utc';
import * as React from "react";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';


import { getProductClass } from "../../utils/Constants";
import { getStocksByUserEvents } from "../../utils/capture";
import AuthConsumer from "../../contexts/auth";

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.locale('en-in')


const columns = [
    { field: "id", headerName: "S.No", width: 60, hide: true, sortable: false },
    {
        field: "gtin",
        headerName: "Product",
        type: "string",
        headerAlign: "left",
        width: 300,
        renderCell: (params) => (
            <Stack alignContent="left">
                <div style={{ textAlign: "left" }}>
                    <Typography variant="caption">{params.value}</Typography>
                </div>
            </Stack>
        ),
    },
    {
        field: "total_quantity",
        headerName: "Total Quantity",
        type: "int",
        width: 100,
        align: "right",
    },
    {
        field: "uom",
        headerName: "Unit",
        type: "string",
        width: 100,
    },
    {
        field: "events_count",
        headerName: "Total Events",
        type: "int",
        width: 100,
        align: "right",
    },
];


export function TransactionSummary({ pageSize }) {
    const { user, getToken } = AuthConsumer();
    const [searching, setSearching] = React.useState(false);
    const [results, setResults] = React.useState([]);

    const [userEvent, setUserEvent] = React.useState("Packing");

    const localSearchStart = dayjs().startOf('month').toISOString();
    const [searchStart, setSearchStart] = React.useState(localSearchStart);

    const localSearchEnd = dayjs().endOf('month').toISOString();
    const [searchEnd, setSearchEnd] = React.useState(localSearchEnd);



    const search = async () => {
        setSearching(true);
        const token = await getToken();
        const searchObj = {
            "owning_gln": user.profile.og[0],
            "user_event": userEvent,
            "start_search_time_epoch": dayjs(searchStart).unix(),
            "end_search_time_epoch": dayjs(searchEnd).unix() 
        }
        const searchResults = await getStocksByUserEvents(token, searchObj)
        const temp = searchResults.stocks.map((item, idx) => {
            item["id"] = idx + 1
            item["gtin"] = getProductClass(item["gtin"]).entity
            return item
        })
        setResults(temp)
        setSearching(false);
    }

    return (
        <Stack sx={{ m: 1, p: 1 }} spacing={2} alignItems="center">
            <Stack direction="row" alignItems="center" justifyContent="center"
                sx={{ flexWrap: 'wrap', gap: 2 }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <MobileDateTimePicker label="after"
                        value={dayjs(searchStart)}
                        onChange={(newValue) => setSearchStart(newValue.toISOString())}

                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <MobileDateTimePicker label="before"
                        value={dayjs(searchEnd)}
                        onChange={(newValue) => setSearchEnd(newValue.toISOString())}

                    />
                </LocalizationProvider>

                <FormControl>
                    <InputLabel id="user_event-select-label">Event</InputLabel>
                    <Select
                    labelId="user_event-select-label"
                    id="user_event-select"
                    value={userEvent}
                    label="Event"
                    onChange={(newvalue) => setUserEvent(newvalue.target.value)}
                    >
                    <MenuItem value={"Packing"}>Packing</MenuItem>
                    <MenuItem value={"Fulfillment"}>Fulfillment</MenuItem>
                    <MenuItem value={"Inspection"}>Inspection</MenuItem>
                    </Select>
                </FormControl>

                {searching ? <CircularProgress size={36} /> :
                    <Button variant="contained" onClick={() => search()}>
                        <Typography variant="body1">
                            Search
                        </Typography>
                    </Button>
                }
            </Stack>

            {(results.length > 0) ?
                <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={results}
                        columns={columns}
                        getRowHeight={() => "auto"}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: (pageSize !== undefined ? pageSize : 10),
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                </Box> : <Typography variant="body2">
                    No Results Found!
                </Typography>
            }
        </Stack>
    )
}