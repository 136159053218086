import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LinearProgress, Stack } from '@mui/material';

export function LinearProgressWithLabel(props) {
    return (
        <Stack direction="column" alignItems="center" spacing={1}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column" }}> */}
            {(props.message !== undefined && props.message !== "") &&
                <Typography>{props.message}</Typography>
            }
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress {...props} sx={{width: "100%"}} />
            </Box>
            {(props.variant !== undefined && props.variant === "determinate") &&
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      ({props.current} / {props.total} orders)  {props.value} %
                    </Typography>
                </Box>}
        </Stack>
    );
}

LinearProgressWithLabel.propTypes = {
    message: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    variant: PropTypes.string.isRequired
};