import { useNavigate } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import React from "react";

import useLocalStorage from "../hooks/useLocalStorage";
import { landingUrl } from "../utils/Constants";

const authContext = React.createContext();

function useAuth() {
  const localUser = JSON.parse(localStorage.getItem('user')) || null;
  const [user, setUser] = useLocalStorage("user", localUser);
  let navigate = useNavigate();

  const loginWithGoogle = () => {
    Auth.federatedSignIn({ provider: "Google" });
  }

  const login = async (username, password) => {
    const userData = await Auth.signIn(username, password);
    setUser(getUser(userData));
    navigate(landingUrl);
  }

  const logout = () => {
    Auth.signOut();
  }

  const getToken = async () => {
    const tokens =  await Auth.currentSession();
    console.log("obtained token")
    const token = tokens.idToken.jwtToken

    return token;
  }
  
  React.useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          break;
        case 'cognitoHostedUI':
          getUserData().then(userData => {
            setUser(getUser(userData));
            navigate(landingUrl);
          });
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        case 'tokenRefresh':
          console.log('token refresh succeeded');
          getUserData().then(userData => {
            setUser(getUser(userData));
          });
          break;
        case 'tokenRefresh_failure':
          console.log('token refresh failed');
          break;
        case 'configured':
          console.log('the Auth module is configured');
          break;
        default:
          console.log('received event : ' + event);
      }
    });

    getUserData().then(userData => {
      setUser(getUser(userData))
    });

    return () => {
      // unsubscribe here
      // Hub.remove("auth", setUser(null))
    };
  // eslint-disable-next-line
  },[]) 

  function getUserData() {
    return Auth.currentUserInfo()
      .then(userData => userData)
      .catch((err) => {
        console.log(err)
        console.log('Not signed in')
      });
  }

  function getUser(userData) {
    if (userData === undefined || userData === null) {
      return null
    }
    const firstName = userData.attributes.given_name
    const lastName = userData.attributes.family_name
    const shortName = firstName.charAt(0) + lastName.charAt(0)
    const email = userData.attributes.email
    const profile = getProfile(userData.attributes)
    return  {
      shortName,
      email,
      profile
    }
  }

  function getProfile(attributes) {
    if (attributes === undefined || attributes === null) {
      return {}
    }
    if (attributes.profile === undefined || attributes.profile === null) {
      return {}
    }
    // "og:8904368510003,admin;roles:admin"

    const profile = attributes.profile.split(";")
    const userProfile = {}
    for (let p of profile) {
      const attrs = p.split(":")
      userProfile[attrs[0]] = attrs[1].split(",")
    }
    return userProfile
  }

  return {
    user,
    login,
    loginWithGoogle,
    logout,
    getToken
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}