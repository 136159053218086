import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MiniDocument from "./MiniDocument";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)
export default function MiniPdfModal({orderList}) {
  
  return (
    <>
      <div style={downloadStyle}>
        <PDFDownloadLink document={<MiniDocument ol={orderList}/>} fileName={ "Orders-Mini-" + dayjs().utc().unix() + ".pdf"}>
          {({ url, loading, error }) => {
            // console.log(url, error);
            return loading ? 'Loading document...' : `Download! (${orderList.length} labels)`
          }
          }
        </PDFDownloadLink>
      </div>
    </>
  );
}

const downloadStyle = {
  display: "block",
  margin: "45 auto",
  width: "100%",
};
