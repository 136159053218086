import * as React from "react";

import { TabPanel, a11yProps } from "../Common/TabPanel";
import RecentEvents from './recent_events';
import { Box, Button, Typography, Tabs, Tab, Stack, TextField } from "@mui/material";
import { EventSearch } from "./event_search";
import { NumericFormat } from 'react-number-format';
import { TCamera } from '../Common/TCamera';
import { Submit } from './submit';


export default function Inspection({ eventDetail, onExit, location }) {
    const [selectedOutput, setSelectedOutput] = React.useState(null);
    const [quantity, setQuantity] = React.useState(1);
    const [photoDataUri, setPhotoDataUri] = React.useState("");

    const [hasValidData, setHasValidData] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [productInfo, setProductInfo] = React.useState(null);
    // console.log(eventDetail);

    React.useEffect(() => {
        const updateSubmission = () => {
            if (selectedOutput === null) {
                setProductInfo(null);
                setHasValidData(false);
            } else {
                const productObj = {
                    "instance_class": selectedOutput.instance_class,
                    "gtin": selectedOutput.gs1_id,
                    "inputs": selectedOutput.instance_id,
                    "quantity": quantity,
                    "uom": selectedOutput.uom,
                };
                setProductInfo(productObj);
                setHasValidData(true);
            }
        }
        updateSubmission()
    }, [selectedOutput, photoDataUri, quantity])

    const reset = () => {
        selectedOutput.available_quantity -= quantity
        setSelectedOutput(null);
        setPhotoDataUri("");
    }

    const back = () => {
        onExit()
    }

    const materialUITextFieldProps = {
        variant: "standard",
        inputProps: { style: { fontSize: 18, textAlign: "center" } }
    };

    const quantityHelper = (min, max) => {
        if (min === max) {
            return ""
        } else if (min < max) {
            return "enter a value between " + min + " - " + max
        } else if (min > 0 && max === -1) {
            return "enter 1 or more"
        }
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (<>
        <Box sx={{ width: '100%' }}>
            <Button variant="contained" size="large" sx={{ width: 100, p: 1, m: 1 }}
                onClick={() => back()} disabled={isSubmitting}>
                <Typography>
                    Back
                </Typography>
            </Button>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} centered aria-label="orders tabs">
                    <Tab label="Capture" {...a11yProps(0)} />
                    <Tab label="Recent Events" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
                <RecentEvents eventType={eventDetail.user_event} />
            </TabPanel>
            <TabPanel value={value} index={0}>
                <Stack spacing={2} alignItems="center">

                    <EventSearch params={eventDetail.inputs_event_search} disabled={isSubmitting}
                        selected={selectedOutput} setSelected={setSelectedOutput} />

                    {selectedOutput !== null && 
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h6">Unstock Quantity: </Typography>
                            <NumericFormat value={quantity} allowNegative={false} decimalScale={2}
                                onChange={(evt) => setQuantity(evt.target.value)}
                                customInput={TextField} {...materialUITextFieldProps}
                                helperText={quantityHelper(1, selectedOutput.available_quantity)}
                                disabled={isSubmitting}
                                isAllowed={(values) => {
                                    const { floatValue, value } = values
                                    if (value === "")
                                        return true
                                    return (floatValue >= 1 && floatValue <= selectedOutput.available_quantity)
                                }}
                            />
                            <Typography variant="h6">{selectedOutput.uom}</Typography>
                        </Stack>
                    }
                    {selectedOutput !== null &&
                        <TCamera setPhotoDataUri={setPhotoDataUri} photoDataUri={photoDataUri} disabled={isSubmitting} />
                    }
                
                <Submit eventDetail={eventDetail} location={location} productInfo={productInfo}
                        existingOutputs={selectedOutput}
                        photoDataUri={photoDataUri} readyToSubmit={hasValidData}
                        reset={reset}
                        isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                </Stack>
            </TabPanel>
        </Box>
    </>)
}