export const events_master = {
    "products": {
        "8904368514047": {
            "gs1_id": "8904368514047",
            "entity": "ALPHONSO MANGOES",
            "instance_class": "LGTIN",
        },
        "8904368514048": {
            "gs1_id": "8904368514048",
            "entity": "RATNA MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514049": {
            "gs1_id": "8904368514049",
            "entity": "MALLIKA MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514050": {
            "gs1_id": "8904368514050",
            "entity": "IMAMPASAND MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514051": {
            "gs1_id": "8904368514051",
            "entity": "SINDORA MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514052": {
            "gs1_id": "8904368514052",
            "entity": "AMRAPALI MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514053": {
            "gs1_id": "8904368514053",
            "entity": "CHANDRAKARAN MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368514054": {
            "gs1_id": "8904368514054",
            "entity": "SALEM BANGALORA",
            "instance_class": "LGTIN"
        },
        "8904368514055": {
            "gs1_id": "8904368514055",
            "entity": "KALAPADI MANGOES",
            "instance_class": "LGTIN"
        },
        "8904368516036": {
            "gs1_id": "8904368516036",
            "entity": "ALPHONSO MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516037": {
            "gs1_id": "8904368516037",
            "entity": "RATNA MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516038": {
            "gs1_id": "8904368516038",
            "entity": "MALLIKA MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516039": {
            "gs1_id": "8904368516039",
            "entity": "IMAMPASAND MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516040": {
            "gs1_id": "8904368516040",
            "entity": "SINDORA MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516041": {
            "gs1_id": "8904368516041",
            "entity": "AMRAPALI MANGOES CASE (3 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516042": {
            "gs1_id": "8904368516042",
            "entity": "CHANDRAKARAN MANGOES CASE (2 KG)",
            "instance_class": "LGTIN"
        },
        "8904368516043": {
            "gs1_id": "8904368516043",
            "entity": "SALEM BANGALORA CASE (3KG)",
            "instance_class": "LGTIN"
        },
        "8904368516044": {
            "gs1_id": "8904368516044",
            "entity": "KALAPADI MANGOES CASE (3KG)",
            "instance_class": "LGTIN"
        }
    },
    "locations": [
        {
            "name": "ARDRA ORGANICS FARM - BEDDAMPATTI",
            "gln": "8904368510015",
            "owning_gln": "8904368510003"
        },
        {
            "name": "ARDRA ORGANICS BANGALORE",
            "gln": "8904368510016",
            "owning_gln": "8904368510003"
        }
    ],
    "events": [
        {
            "user_event": "Harvesting",
            "event_access": "public",
            "epcis_event": "object",
            "epcis_action": "add",
            "biz_step": "commissioning",
            "default_location": "8904368510015",
            "owning_gln": "8904368510003",
            "requires_photo": true,
            "consumer_trace": true,
            "locations": ["8904368510015"],
            "roles": ["harvester", "admin"],
            "input_stock_mgmt": false,
            "output_stock_mgmt": false,
            "input_label": "",
            "inputs": [
            ],
            "output_label": "Select Produce",
            "outputs": [
                {
                    "gs1_id": "8904368514047",
                    "entity": "ALPHONSO MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514048",
                    "entity": "RATNA MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514049",
                    "entity": "MALLIKA MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514050",
                    "entity": "IMAMPASAND MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514051",
                    "entity": "SINDORA MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514052",
                    "entity": "AMRAPALI MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514053",
                    "entity": "CHANDRAKARAN MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514054",
                    "entity": "SALEM BANGALORA",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                },
                {
                    "gs1_id": "8904368514055",
                    "entity": "KALAPADI MANGOES",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "lot",
                    "min_quantity": 1,
                    "max_quantity": 1,
                }
            ]
        },
        {
            "user_event": "Stacking",
            "event_access": "public",
            "epcis_event": "object",
            "epcis_action": "observe",
            "biz_step": "storing",
            "default_location": "8904368510015",
            "owning_gln": "8904368510003",
            "requires_photo": true,
            "consumer_trace": true,
            "locations": ["8904368510015"],
            "roles": ["harvester", "admin"],
            "input_stock_mgmt": false,
            "output_stock_mgmt": false,
            "input_label": "",
            "inputs": [
            ],
            "output_label": "",
            "outputs": [
            ],
            "inputs_event_search": {},
            "outputs_event_search": {
                "user_event": "Harvesting",
                "owning_gln": "8904368510003",
                "date_filter": true,
                "from_last_days": 0,
                "to_last_days": 0,
                "use_inputs": false,
                "use_outputs": true,
                "stocks": false,
            }
        },
        {
            "user_event": "Packing",
            "event_access": "public",
            "epcis_event": "aggregation",
            "epcis_action": "add",
            "biz_step": "packing",
            "default_location": "8904368510015",
            "owning_gln": "8904368510003",
            "requires_photo": true,
            "consumer_trace": true,
            "locations": ["8904368510015"],
            "roles": ["harvester", "admin"],
            "input_stock_mgmt": false,
            "output_stock_mgmt": true,
            "inputs_event_search": {
                "user_event": "Stacking",
                "owning_gln": "8904368510003",
                "date_filter": true,
                "from_last_days": 5,
                "to_last_days": 3,
                "use_inputs": false,
                "use_outputs": true,
                "stocks": false,
            },
            "input_label": "",
            "inputs": [],
            "output_label": "Select Mango Cases",
            "outputs": [
                {
                    "gs1_id": "8904368516036",
                    "entity": "ALPHONSO MANGOES (3 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514047"],
                },
                {
                    "gs1_id": "8904368516037",
                    "entity": "RATNA MANGOES (3 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514048"]
                },
                {
                    "gs1_id": "8904368516038",
                    "entity": "MALLIKA MANGOES (3 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514049"]
                },
                {
                    "gs1_id": "8904368516039",
                    "entity": "IMAMPASAND MANGOES (3 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514050"]
                },
                {
                    "gs1_id": "8904368516040",
                    "entity": "SINDORA MANGOES (3 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514051"]
                },
                {
                    "gs1_id": "8904368516041",
                    "entity": "AMRAPALI MANGOES (3 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514052"]
                },
                {
                    "gs1_id": "8904368516042",
                    "entity": "CHANDRAKARAN MANGOES (2 KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514053"]
                },
                {
                    "gs1_id": "8904368516043",
                    "entity": "SALEM BANGALORA (3KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514054"]
                },
                {
                    "gs1_id": "8904368516044",
                    "entity": "KALAPADI MANGOES CASE (3KG)",
                    "instance_class": "LGTIN",
                    "default_quantity": 1,
                    "uom": "case",
                    "min_quantity": 1,
                    "max_quantity": -1,
                    "matching_inputs": ["8904368514055"]
                }
            ],
            "outputs_event_search": {}
        },
        {
            "user_event": "Inspection",
            "event_access": "private",
            "epcis_event": "object",
            "epcis_action": "delete",
            "biz_step": "destroying",
            "default_location": "8904368510016",
            "owning_gln": "8904368510003",
            "requires_photo": false,
            "consumer_trace": false,
            "input_stock_mgmt": true,
            "output_stock_mgmt": false,
            "locations": ["8904368510016"],
            "roles": ["admin"],
            "outputs": [
            ],
            "inputs": [
            ],
            "inputs_event_search": {
                "user_event": "Packing",
                "owning_gln": "8904368510003",
                "date_filter": true,
                "from_last_days": 5,
                "to_last_days": 0,
                "use_inputs": false,
                "use_outputs": true,
                "stocks": true
            },
            "outputs_event_search": {},
        },
        {
            "user_event": "Fulfillment",
            "event_access": "public",
            "epcis_event": "object",
            "epcis_action": "delete",
            "default_location": "8904368510016",
            "owning_gln": "8904368510003",
            "biz_step": "fulfillment",
            "biz_transaction_type": "order",
            "requires_photo": false,
            "consumer_trace": false,
            "input_stock_mgmt": true,
            "output_stock_mgmt": false,
            "locations": ["8904368510016"],
            "roles": ["admin"],
            "inputs": [
            ],
            "outputs": [
            ],
            "outputs_event_search": {
            },
            "inputs_event_search": {}
        }
    ]
}