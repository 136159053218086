import {
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import * as React from "react";


export function OrdersToolBar() {
    return (
        <GridToolbarContainer sx={{ m: 1 }}>
            <GridToolbarFilterButton variant="outlined" />
            
        </GridToolbarContainer>
    );
}
