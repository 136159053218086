import localizedFormat from "dayjs/plugin/localizedFormat";
import { toDate, formatInTimeZone } from 'date-fns-tz';
import { enIN } from 'date-fns/locale';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/en-in";
import dayjs from 'dayjs';

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.locale('en-in')


export const getDate = () => {
  return (new Date());
};

export const extractDate = (date) => {
  const dateObj = new Date(date);
  let month = '' + (dateObj.getMonth() + 1);
  let day = '' + dateObj.getDate();
  let year = dateObj.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
};

export const simpleDate = () => {
  return dayjs().format(' MMM DD YYYY')
}

/*
* Timestamp in ISO 8601 with timezone offset
*
*/
export const toIsoString = (date) => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

// 2021-11-01T08:30:22+05:30 -> 2021-11-01 08:30 AM IST
export const toISTFormatString = (dateStr) => {
  const parsedDate = toDate(dateStr)
  return formatInTimeZone(parsedDate, 'Asia/Calcutta', 'E, dd MMM yyyy hh:mm a zzz', { locale: enIN })
};

// 2021-11-01 -> 2021-11-01 08:30 AM IST
export const toDateString = (dateStr) => {
  const parsedDate = toDate(dateStr)
  return formatInTimeZone(parsedDate, 'Asia/Calcutta', 'E, dd MMM yyyy', { locale: enIN })
};

export const getLocaleDateTime = (epochInSecs) => {
  return dayjs(epochInSecs * 1000).format("L h:mm A")
}