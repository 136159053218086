import { Stack, Box, Tabs, Tab, Button, Typography } from '@mui/material';
import * as React from 'react';

import { TabPanel, a11yProps } from "../Common/TabPanel";
import { EventSearch } from './event_search';
import { TCamera } from '../Common/TCamera';
import RecentEvents from './recent_events';
import { Submit } from './submit';

export function Observation({ location, eventDetail, onExit }) {
    const [photoDataUri, setPhotoDataUri] = React.useState("");
    const [selectedOutput, setSelectedOutput] = React.useState(null);
    const [hasValidData, setHasValidData] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const reset = () => {
        setSelectedOutput(null);
        setPhotoDataUri("");
    }

    const back = () => {
        onExit();
    }

    React.useEffect(() => {
        const updateSubmission = () => {
            if (selectedOutput !== null && photoDataUri !== "") {
                setHasValidData(true);
            } else {
                setHasValidData(false);
            }
        }
        updateSubmission()
    }, [selectedOutput, photoDataUri]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Button variant="contained" size="large" sx={{ width: 100, p: 1, m: 1 }}
                onClick={() => back()} disabled={isSubmitting}>
                <Typography>
                    Back
                </Typography>
            </Button>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} centered aria-label="orders tabs">
                    <Tab label="Capture" {...a11yProps(0)} />
                    <Tab label="Recent Events" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
                <RecentEvents eventType={eventDetail.user_event} />
            </TabPanel>
            <TabPanel value={value} index={0}>
                <Stack spacing={2} alignItems="center">
                    <EventSearch params={eventDetail.outputs_event_search} disabled={isSubmitting}
                        selected={selectedOutput} setSelected={setSelectedOutput} />

                    {/* add a new stacking image */}
                    {(selectedOutput !== null) && <TCamera setPhotoDataUri={setPhotoDataUri} photoDataUri={photoDataUri} disabled={isSubmitting} />}

                    {/* submit event */}
                    <Submit eventDetail={eventDetail} location={location}
                        existingOutputs={selectedOutput}
                        photoDataUri={photoDataUri} readyToSubmit={hasValidData}
                        reset={reset}
                        isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />

                </Stack >
            </TabPanel>
        </Box>
    )

}