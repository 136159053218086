import { React } from "react";
import { Font, Page, Text, View, Document, Image } from "@react-pdf/renderer";
import RobotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import RobotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import RobotoItalic from "../../fonts/Roboto/Roboto-Italic.ttf";


function MiniDocument(props) {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular,
      },
      {
        src: RobotoItalic,
        fontStyle: 'italic'
      },
      {
        src: RobotoBold,
        fontWeight: 'bold'
      },
    ]
  });
  const orderList = props.ol;

  const imageURL = (instance_id) => {
    const encodedUrl = encodeURIComponent(process.env.REACT_APP_AWS_TRACE_UI + `?trace_item=${instance_id}&env=${process.env.REACT_APP_AWS_PUBLIC_TRACE_API}`)
    // console.log(encodedUrl);
    return `https://quickchart.io/chart?cht=qr&chl=${encodedUrl}&chs=180x180&choe=UTF-8&chld=L|4`;
  }

  const createTableRows = (item) => {
    return (
      <>
        {/* row1 */}
        <View style={mainTableRowStyle}>
          <View style={viewScanTextStyle}>
            <Text style={scanTextStyle}>Scan QR code to trace your food's journey!</Text>
          </View>
          <View style={tableQrCodeColStyle}>
            <Image
              src={imageURL(item.instance_id)}
              style={qr}
              crossOrigin="anonymous"
            />
            <Text style={taglineTextStyle}>Powered By</Text>
            <Image
                src="https://api.startupindia.gov.in/sih/api/file/user/image/Startup?fileName=ffd2894d-a625-41e3-bafe-b88b4d293289.png"
                style={companyLogo}
                crossOrigin="anonymous"
              />
          </View>
          <View style={tableColStyle}>
            <View style={tableRowStyle}>
              <Text style={companyTitleCellStyle}>ardra Organics </Text>
            </View>
            <View style={tableRowStyle}>
              <View style={tableRowOrderDetailsStyle}>
                <Text style={orderIdCellStyle}>Order ID: {item.orderId}</Text>
                <Text style={orderIdCellStyle}>{item.itemIdx}</Text>
                <Text style={customerIdCellStyle}>
                  {item.shippingAddress.customer_name}
                </Text>
                {/* <Text style={customerIdCellStyle}>
                  Customer (ID): {item.order_detail.shipping_properties.first_name} {item.order_detail.shipping_properties.last_name} ({item.order_detail.customer_id})
                </Text> */}
                <Text style={tableCellAddressStyle}>
                  {item.shippingAddress.address_1}, {item.shippingAddress.building_name}, {item.shippingAddress.landmark}, {item.shippingAddress.locatlity}, {item.shippingAddress.city}, {item.shippingAddress.state}, {item.shippingAddress.postcode}
                </Text>
                <Text style={tableCellAddressStyle}>
                  {item.shippingAddress.mobile}
                </Text>
              </View>
            </View>
          </View>

        </View>
        {/* row2 */}

        <View style={colspan}>
          <Text style={H4Style}>Order Item</Text>
            <div style={{ width: "95%", paddingRight: 2 }}>
              <Text style={orderLineItemStyle}>{item.entity}</Text>
              {/* SKU need not be displayed in the tracing slip
              <Text style={orderLineItemStyle}>({i.sku})</Text> */}
            </div>
            <div style={{ width: "95%" }}>
              <Text style={orderLineItemStyle}>Packed on: {item.packedOn}</Text>
            </div>
        </View>
      </>
    );

  };

  return (
    <Document>
      {orderList &&
        orderList.map((item, index) => (
          <Page
            style={pageStyle}
            size="A7"
            orientation="landscape"
            key={item.orderId}
          >
            <View style={tableStyle} >{createTableRows(item)}</View>
          </Page>
        ))}
    </Document>
  );
}

const orderLineItemStyle = {
  textAlign: "left",
  margin: 0,
  marginLeft: 0,
  fontSize: 9,
  fontFamily: "Roboto",
};

const companyLogo = {
  height: "20px",
  width: "80px",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  margin: 0,
  padding: 0,
};

const pageStyle = {
  paddingTop: 1,
  paddingHorizontal: 4,
  paddingBottom: 5,
};

const qr = {
  height: "100px",
  width: "100px",
  //marginLeft: "5px",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  margin: 0,
  padding: 0
};

const H4Style = {
  fontFamily: "Roboto",
  fontSize: 9,
  fontWeight: "bold",
  marginTop: 2,
  // marginLeft: 10,
  marginBottom: 2,
  textAlign: "left",
}

const tableStyle = {
  display: "table",
  width: "850px",
  height: "auto",
};

const tableRowStyle = {
  flexDirection: "row",
  position: "relative"
};

const mainTableRowStyle = {
  flexDirection: "row",
  position: "relative",
  marginLeft: 10,
  marginRight: 10,
  marginTop: 3,
  height: 150
};

const colspan = {
  width: "472px",
  marginLeft: 10,
  marginRight: 10
};

const tableColStyle = {
  width: "150px",
  left: "125px",
  border: "none",
  // backgroundColor: "red",
  // textAlign: "left",
  position: "absolute",
};

const tableRowOrderDetailsStyle = {
  width: "95%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 0,
  margin: 0,
  padding: 0,
};

const tableQrCodeColStyle = {
  width: "100px",
  borderStyle: "none",
  textAlign: "center",
  padding: 0,
  transform: "translate(-80,0)"
};

const orderIdCellStyle = {
  fontFamily: "Roboto",
  textAlign: "right",
  marginBottom: 4,
  fontSize: 16,
  fontWeight: 'bold',
};

const companyTitleCellStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 14,
  fontWeight: 'bold',
}

const customerIdCellStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 10,
  fontWeight: 'bold',
}

const viewScanTextStyle = {
  width: 100,
  height: 25,
  borderStyle: "solid",
  borderColor: "white",
  // borderBottomWidth: 0,
  borderWidth: 1,
  transform: "rotate(-90deg)translate(-40,-40)",
}

const scanTextStyle = {
  fontFamily: "Roboto",
  textAlign: "center",
  fontWeight: 'bold',
  fontSize: 9,
  margin: 0,
  padding: 0,

}

const taglineTextStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  fontWeight: 'bold',
  fontSize: 9,
  marginLeft: 10,
  padding: 0,

}

const tableCellAddressStyle = {
  fontFamily: "Roboto",
  textAlign: "left",
  margin: 1,
  fontSize: 10,
  maxWidth: 300,
  width: "auto"
};

export default MiniDocument;
