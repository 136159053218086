/* eslint-disable */// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const localhostRedirect="http://localhost:3000/";
const awsStagingRedirect="https://www.taas-stg.tralexho.com/,https://taas-stg.tralexho.com/,https://main.d3jnartn4tsa0v.amplifyapp.com/";
const awsProdRedirect="https://www.taas-ard.tralexho.com,https://taas-ard.tralexho.com,https://prod-main.d3s7hsyd5xh9s.amplifyapp.com";

let redirectUrls = [localhostRedirect];

if (process.env.REACT_APP_BUILD_ENV === "aws-staging") {
    redirectUrls.push(awsStagingRedirect);
} else if (process.env.REACT_APP_BUILD_ENV === "aws-prod") {
    redirectUrls.push(awsProdRedirect);
} else {
    // for successful login process, amplify aws auth requires multiple redirect uris (a local and a prod)
    // adding the eval as a dummy entry and will not be used for local development
    redirectUrls.push(awsStagingRedirect);
}

const awsmobile = {
    "aws_project_region": "ap-south-1",
    // "aws_cognito_identity_pool_id": "ap-south-1:0a3b2c41-3bb6-4280-900d-d44194845636",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_dByzSJ7Nb",
    // "aws_user_pools_web_client_id": "ncn0qbelp9d1u79gfph5eamln",
    "aws_user_pools_web_client_id": "rptoejd469atci36e3juvu9ci",
    "oauth": {
        "domain": "txo-app-users.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": redirectUrls.join(","),
        "redirectSignOut": redirectUrls.join(","),
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
