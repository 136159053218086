import { Box, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import * as React from 'react';

import { getEvents, getLocations } from '../../utils/Constants';
import { EventConsumer } from '../../contexts/events';
import AuthConsumer from '../../contexts/auth';
import { Observation } from './observation';
import { Commission } from './commission';
import { Packing } from './packing';
import OrdersFulfillment from './order_fulfillment';
import Inspection from './inspection';

export function EventRecording() {
    const { user } = AuthConsumer();
    const { currentEvent, setCurrentEvent, setEventRecording, currentLocation, setCurrentLocation } = EventConsumer();
    const [eventDetail, setEventDetail] = React.useState({})
    const [locations, setLocations] = React.useState([])

    const reset = () => {
        setCurrentEvent("")
        setEventRecording(false)
    };


    React.useEffect(() => {
        const eventDetail = getEvents(user, currentEvent);
        const locations = getLocations(eventDetail.locations);
        setLocations(locations)

        for (const loc of locations) {
            if (loc.gln === eventDetail.default_location) {
                setCurrentLocation(loc);
                break;
            }
        }
        setEventDetail(eventDetail)
    }, [currentEvent, user, setCurrentLocation])

    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            p: 1,
            m: 1,
            color: 'black'
        }}
        >

            <Stack direction="column" spacing={3} alignItems="left" sx={{ width: "95%" }}>
                {(locations.length > 0) &&
                    <FormControl >
                        <InputLabel id="location-select-label">Location</InputLabel>
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            label="Location"
                            value={currentLocation}
                            onChange={(evt) => {
                                console.log(evt.target.value);
                                setCurrentLocation(evt.target.value);
                            }}
                        >
                            {locations.map((item) => (
                                <MenuItem key={item.gln} value={item}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                {(eventDetail.epcis_event === "object" && eventDetail.epcis_action === "add") &&
                    <Commission eventDetail={eventDetail} onExit={reset} location={currentLocation} />
                }
                {(eventDetail.epcis_event === "object" && eventDetail.epcis_action === "observe") &&
                    <Observation eventDetail={eventDetail} onExit={reset} location={currentLocation} />
                }
                {(eventDetail.epcis_event === "aggregation" && eventDetail.epcis_action === "add"
                    && eventDetail.biz_step === "packing") &&
                    <Packing eventDetail={eventDetail} onExit={reset} location={currentLocation} />
                }
                {(eventDetail.epcis_event === "object" && eventDetail.epcis_action === "delete"
                    && eventDetail.biz_step === "destroying") &&
                    <Inspection eventDetail={eventDetail} onExit={reset} location={currentLocation} />
                }

                {(eventDetail.epcis_event === "object" && eventDetail.epcis_action === "delete" 
                    && eventDetail.biz_step === "fulfillment" && eventDetail.biz_transaction_type === "order") &&
                    <OrdersFulfillment eventDetail={eventDetail} onExit={reset} location={currentLocation} />
                }
                

            </Stack></Box>
    )
}