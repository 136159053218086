import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Button, Stack, Typography, LinearProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import * as React from 'react';

import { endEvent, startEvent, uploadImage, addOutputsToEvent, createProductInstance, addInputsToEvent, deductStocks } from '../../utils/capture';
import { getCurrentLocation, roundNum } from '../../utils/Constants';
import AuthConsumer from '../../contexts/auth';
import { simpleDate, toIsoString } from '../../utils/date';
import { Confirmation } from './confirmation';

export function Submit({
    eventDetail, productInfo, location,
    existingInputs, existingOutputs,
    photoDataUri, readyToSubmit, reset,
    isSubmitting, setIsSubmitting
}) {
    const { user, getToken } = AuthConsumer();
    const [isCreatingEvent, setIsCreatingEvent] = React.useState(false)
    const [isCreatingProduct, setIsCreatingProduct] = React.useState(false)
    const [isAddingImage, setIsAddingImage] = React.useState(false)

    const [confirmationData, setConfirmationData] = React.useState({})
    const [confirmationOpen, setConfirmationOpen] = React.useState(false)

    // Data
    let position = React.useRef(null);
    let event = React.useRef(null);
    let product = React.useRef(null);
    let outputs = React.useRef(null);
    let outputsAdded = React.useRef(false);
    let photoAdded = React.useRef(false);
    let stockUpdated = React.useRef(false);
    let eventClosed = React.useRef(false);

    const process = async () => {
        setIsSubmitting(true)
        setIsCreatingEvent(true);
        setIsCreatingProduct(true);

        // console.log(position.current, event.current, product.current, outputs.current);
        // console.log(outputsAdded.current, photoAdded.current, eventClosed.current);

        const authToken = await getToken();
        const now = new Date();
        const iso_now = toIsoString(now);
        try {
            if (position.current === null) {
                // console.log("getting location")
                position.current = await getCurrentLocation();
            }
            // 1. create event
            if (event.current === null) {
                const eventObj = {
                    ...position,
                    "epcis_event": eventDetail.epcis_event,
                    "event_access": eventDetail.event_access,
                    "user_event": eventDetail.user_event,
                    "epcis_action": eventDetail.epcis_action,
                    "biz_step": eventDetail.biz_step,
                    "biz_transaction_type": eventDetail.user_event,
                    "biz_transaction_id": eventDetail.user_event,
                    "event_start_datetime": iso_now,
                    "created_by": user.email,
                    "owning_gln": user.profile.og[0],
                    "location": location.gln,
                }

                // console.log(eventObj)
                event.current = await startEvent(authToken, eventObj);

                if (existingInputs !== undefined) {
                    event.current = await addInputsToEvent(authToken, event.current.event_id, {
                        "input_product_instances": existingInputs.instance_id
                    });
                }
            }
            // console.log(event.current)
            let results = {
                "event_id": event.current.event_id,
                "user_event": event.current.user_event,
                "location_name": location.name,
                "location": location.gln,
                "event_start_datetime": event.current.event_start_datetime,
                "event_timezone_offset": event.current.event_timezone_offset,
                "img_url": ""
            }

            // 2. create product instance  and update event outputs
            // "instance_class": currentOutputDetail.instance_class,
            // "gtin": currentOutputDetail.gs1_id,
            // "inputs": inputs,
            // "quantity": quantity,
            // "uom": currentOutputDetail.uom,
            if (existingOutputs !== undefined && (outputs.current === null || !outputsAdded.current)) {
                // console.log("adding existing output")
                outputs.current = {
                    "output_product_instances": existingOutputs.instance_id
                }

                event.current = await addOutputsToEvent(authToken, event.current.event_id, outputs.current);
                outputsAdded.current = true;
                setIsCreatingProduct(false);

                results["instance_id"] = event.current.output_product_instances
            } else if (productInfo !== undefined && (product.current === null || !outputsAdded.current)) {
                // console.log("adding new output")
                const productObj = {
                    ...productInfo,
                    "event_id": event.current.event_id,
                    "created_at": iso_now,
                    "location_gln": location.gln,
                    "owning_gln": user.profile.og[0],
                    "add_to_stock": eventDetail.output_stock_mgmt
                }
                product.current = await createProductInstance(authToken, productObj);
                outputs.current = {
                    "output_product_instances": product.current[0].instance_id
                };
                event.current = await addOutputsToEvent(authToken, event.current.event_id, outputs.current);
                outputsAdded.current = true;
                setIsCreatingProduct(false);

                results = { ...results, ...productInfo }
                results["instance_id"] = event.current.output_product_instances
            }

            // 3. add image to the event
            if (photoDataUri !== undefined && photoDataUri !== "" && !photoAdded.current) {
                setIsAddingImage(true);
                const img = photoDataUri.replace("data:image/jpeg;base64,", "")
                const photoObj = {
                    "event_id": event.current.event_id,
                    "instance_id": event.current.output_product_instances,
                    "image_upload_datetime": iso_now,
                    "image_type": "EVENT",
                    "image_location": location.gln,
                    "image_uri": img,
                    "text_1": roundNum(position.current.latitude) + ", " + roundNum(position.current.longitude),
                    "text_2": simpleDate(),
                    "text_3": "Tralexho"
                }
                // console.log(photoObj)
                const image = await uploadImage(authToken, photoObj)
                photoAdded.current = true;
                setIsAddingImage(false);
                results["img_url"] = image.thumbnail_image_url
            }

            // 4. check if stock need to be deducted
            // epcis_event: 'object', epcis_action: 'delete', biz_step: 'destroying'
            if (eventDetail.epcis_event === "object" && eventDetail.epcis_action === "delete" &&
                eventDetail.biz_step === "destroying" && productInfo !== null && !stockUpdated.current) {
                const stockObj = {
                    "instance_id": event.current.output_product_instances,
                    "event_id": event.current.event_id,
                    "stock_quantity": productInfo.quantity,
                    "created_at": iso_now,
                    "gtin": productInfo.gtin,
                    "owning_gln": user.profile.og[0],
                    "location_gln": location.gln,
                    "uom": productInfo.uom
                };
                await deductStocks(authToken, stockObj);
                stockUpdated.current = true;
            }

            // 4. close event
            if (!eventClosed.current) {
                const end_now = new Date();
                const iso_end_now = toIsoString(end_now);
                const endEventObj = {
                    "event_end_datetime": iso_end_now
                }
                await endEvent(authToken, event.current.event_id, endEventObj)
                eventClosed.current = true;
                setIsCreatingEvent(false);
            }

            // 5. show confirmation
            // console.log(results)
            setConfirmationData(results)
            setConfirmationOpen(true)
        } catch (err) {
            setIsSubmitting(false)
            setIsCreatingEvent(false);
            setIsCreatingProduct(false);
            setIsAddingImage(false);
        }
    }

    // const back = () => {
    //     onExit()
    // }

    const resetForm = () => {
        position.current = null;
        event.current = null;
        product.current = null;
        outputs.current = null;
        outputsAdded.current = false;
        photoAdded.current = false;
        stockUpdated.current = false;
        eventClosed.current = false;
        setConfirmationOpen(false);
        setIsSubmitting(false);
        setConfirmationData({});
        reset()
    }


    return (
        <>
            <Stack spacing={2} alignItems="center">
                <Button variant="contained" size="large" sx={{ width: 300, p: 1, m: 1 }} onClick={() => process()} disabled={isSubmitting || !readyToSubmit}>
                    <Typography>
                        Submit
                    </Typography>
                </Button>

                {isSubmitting &&
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                        <Typography variant='h6'>
                            Creating Event {!isCreatingEvent && <CheckCircleRoundedIcon sx={{ color: green[500] }} />}
                        </Typography>
                        {isCreatingEvent && <LinearProgress color="secondary" />}
                        {(productInfo !== null) &&
                            <Typography variant='h6'>
                                Creating Product  {!isCreatingProduct && <CheckCircleRoundedIcon sx={{ color: green[500] }} />}
                            </Typography>}
                        {(isCreatingProduct && (productInfo !== null)) && <LinearProgress color="success" />}
                        {(photoDataUri !== undefined && photoDataUri !== "") && <>
                            <Typography variant='h6'>
                                Adding Image {!isAddingImage && <CheckCircleRoundedIcon sx={{ color: green[500] }} />}
                            </Typography>
                            {isAddingImage && <LinearProgress color="inherit" />}
                        </>
                        }
                    </Stack>
                }
            </Stack>
            {confirmationOpen && <Confirmation open={confirmationOpen} confirmationData={confirmationData} reset={resetForm} />}
        </>
    )
}