import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import * as React from 'react';
import dayjs from "dayjs";
import 'dayjs/locale/en-in';
import utc from 'dayjs/plugin/utc';


import { getImages, getStocks, searchEvents } from '../../utils/capture';
import { getLocaleDateTime } from '../../utils/date';
import { ProductPreview } from './product_preview';
import AuthConsumer from '../../contexts/auth';
dayjs.extend(utc);

export function EventSearch({ params, disabled, selected, setSelected }) {

    const { getToken } = AuthConsumer();
    const localSearchStart = dayjs().startOf('day').subtract(params.from_last_days, "days").toISOString();
    const [searchStart, setSearchStart] = React.useState(localSearchStart);

    const localSearchEnd = dayjs().endOf('day').subtract(params.to_last_days, "days").toISOString();
    const [searchEnd, setSearchEnd] = React.useState(localSearchEnd);

    const [results, setResults] = React.useState([]);


    const [isSearching, setIsSearching] = React.useState(false)

    const getImage = async (authToken, event_id) => {
        const images = await getImages(authToken, { "event_id": event_id })
        if (images.length > 0) {
            return images[0].thumbnail_image_url
        }
        return ""
    }

    const updatedStockInfo = async (token, instance_id, owning_gln) => {
        return await getStocks(token, { 
            "instance_id": instance_id, 
            "owning_gln": owning_gln,
            "is_available": true
        })
    }

    const getUserEvents = async () => {
        setIsSearching(true)
        const token = await getToken();
        const searchObj = {
            search_begin_epoch: dayjs(searchStart).utc().unix(),
            search_end_epoch: dayjs(searchEnd).utc().unix(),
            owning_gln: params.owning_gln,
            user_event: params.user_event,
            page: 1,
            results_limit: 10,
        }
        // console.log(searchObj)
        let events = await searchEvents(token, searchObj)
        events = events.filter(evt => evt.event_end_datetime !== null)
        const products = []
        for (let evt of events) {
            let instance_id = ""
            if (params.use_inputs && evt.input_product_instances !== null) {
                instance_id = evt.input_product_instances
            } else if (params.use_outputs && evt.output_product_instances !== null) {
                instance_id = evt.output_product_instances
            } else {
                continue
            }

            const productObj = {
                "date_time": getLocaleDateTime(evt.event_start_unix_epoch),
                "user_event": evt.user_event,
                "event_id": evt.event_id,
                "instance_id": instance_id,
                "image_url": await getImage(token, evt.event_id),
            }
            const initialStockInfo = {}
            for (const initStock of evt.output_stock_info) {
                initialStockInfo[initStock.instance_id] = initStock.stock_quantity
            }
            if (params.stocks) {
                const stockInfo = await updatedStockInfo(token, instance_id, params.owning_gln)
                if (stockInfo.length > 0) {
                    productObj["available_quantity"] = stockInfo[0].available_quantity;
                    productObj["uom"] = stockInfo[0].uom;
                    productObj["last_update_on"] = getLocaleDateTime(stockInfo[0].last_update_on);
                    productObj["initial_quantity"] = initialStockInfo[instance_id]
                    products.push(productObj)
                }
            } else {
                products.push(productObj)
            }
        }
        // console.log(products);
        setResults(products)
        setIsSearching(false)
    }

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center"
                sx={{ flexWrap: 'wrap', gap: 2 }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <MobileDateTimePicker label={params.user_event + " after"}
                        value={dayjs(searchStart)}
                        onChange={(newValue) => setSearchStart(newValue.toISOString())}

                    />
                </LocalizationProvider>
                {params.to_last_days > 0 &&
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                        <MobileDateTimePicker label={params.user_event + " before"}
                            value={dayjs(searchEnd)}
                            onChange={(newValue) => setSearchEnd(newValue.toISOString())}

                        />
                    </LocalizationProvider>}
                <Button variant="contained" size="large" onClick={() => getUserEvents()}>
                    <Typography>
                        Search
                    </Typography>
                </Button>
            </Stack>
            {/* list the product instances */}
            <Typography variant="h6">{params.user_event} Events {isSearching && <CircularProgress size={20} sx={{ ml: 1 }} />}</Typography>

            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyItems: "center", flexGrow: 1 }}>
                {results && results.map((item) => (
                    (selected === null || item.instance_id === selected.instance_id) &&
                    <ProductPreview product={item} key={item.instance_id}
                        selected={selected} setSelected={setSelected} disabled={disabled} />

                ))}
            </Box>
        </>
    )
}