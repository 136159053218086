import { Stack, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Button, Tabs, Tab, Box } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import * as React from 'react';

import { EventSearch } from './event_search';
import { TCamera } from '../Common/TCamera';
import { Submit } from './submit';
import { TabPanel, a11yProps } from "../Common/TabPanel";
import RecentEvents from './recent_events';


export function Packing({ location, eventDetail, onExit }) {
    const [selectedInput, setSelectedInput] = React.useState(null);

    const [outputs, setOutputs] = React.useState([]);
    const [currentOutput, setCurrentOutput] = React.useState("");
    const [currentOutputDetail, setCurrentOutputDetail] = React.useState({});
    const [quantity, setQuantity] = React.useState("");
    const [photoDataUri, setPhotoDataUri] = React.useState("");

    const [hasValidData, setHasValidData] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [productInfo, setProductInfo] = React.useState(null);


    const updateOutputDetail = (output_id) => {
        for (let output of eventDetail.outputs) {
            if (output.gs1_id === output_id) {
                console.log(output)
                setCurrentOutputDetail(output);
                setQuantity(output.default_quantity);
                break;
            }
        }
    };

    const materialUITextFieldProps = {
        variant: "standard",
        inputProps: { style: { fontSize: 18, textAlign: "center" } }
    };

    const quantityHelper = (min, max) => {
        if (min === max) {
            return ""
        } else if (min < max) {
            return "enter a value between " + min + " - " + max
        } else if (min > 0 && max === -1) {
            return "enter 1 or more"
        }
    }

    // update matching outputs
    React.useEffect(() => {
        if (selectedInput !== null) {
            const gtin = selectedInput.instance_id.split("-")[0]
            setCurrentOutput("")
            setOutputs(eventDetail.outputs.filter((item) => {
                return item.matching_inputs.indexOf(gtin) > -1
            }))
        } else {
            setCurrentOutput("")
            setOutputs([]);
        }
    }, [eventDetail, selectedInput])

    React.useEffect(() => {
        const updateSubmission = () => {
            if (selectedInput === null || photoDataUri === "" || currentOutput === "") {
                setProductInfo(null)
                setHasValidData(false)
            } else {
                const productObj = {
                    "instance_class": currentOutputDetail.instance_class,
                    "gtin": currentOutputDetail.gs1_id,
                    "inputs": selectedInput.instance_id,
                    "quantity": quantity,
                    "uom": currentOutputDetail.uom,
                }
                setProductInfo(productObj)
                setHasValidData(true)
            }
        }
        updateSubmission()
    }, [selectedInput, photoDataUri, quantity, currentOutput, currentOutputDetail])

    const reset = () => {
        setSelectedInput(null);
        setPhotoDataUri("");
        setCurrentOutput("");
    }

    const back = () => {
        onExit()
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Button variant="contained" size="large" sx={{ width: 100, p: 1, m: 1 }}
                onClick={() => back()} disabled={isSubmitting}>
                <Typography>
                    Back
                </Typography>
            </Button>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} centered aria-label="orders tabs">
                    <Tab label="Capture" {...a11yProps(0)} />
                    <Tab label="Recent Events" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
                <RecentEvents eventType={eventDetail.user_event} />
            </TabPanel>
            <TabPanel value={value} index={0}>
                <Stack spacing={2} alignItems="center">
                    <EventSearch params={eventDetail.inputs_event_search} disabled={isSubmitting}
                        selected={selectedInput} setSelected={setSelectedInput} />

                    {(selectedInput !== null && outputs.length > 0) &&
                        <Stack spacing={2} alignItems="center" justifyItems="center">
                            <FormControl>
                                <InputLabel id="outputs-select-label">{eventDetail.output_label}</InputLabel>
                                <Select
                                    labelId="outputs-select-label"
                                    id="outputs-select"
                                    label={eventDetail.output_label}
                                    value={currentOutput}
                                    disabled={isSubmitting}
                                    onChange={(evt) => {
                                        setCurrentOutput(evt.target.value);
                                        setPhotoDataUri("");
                                        updateOutputDetail(evt.target.value)
                                    }}
                                    sx={{ width: 300 }}
                                >
                                    {outputs.length > 0 && outputs.map((item) => (
                                        <MenuItem key={"key" + item.gs1_id} value={item.gs1_id}>{item.entity}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {(currentOutput !== "" && currentOutputDetail && currentOutputDetail.min_quantity > 0) &&
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography variant="h6">Quantity: </Typography>
                                    <NumericFormat value={quantity} allowNegative={false} decimalScale={2}
                                        onChange={(evt) => setQuantity(evt.target.value)}
                                        customInput={TextField} {...materialUITextFieldProps}
                                        helperText={quantityHelper(currentOutputDetail.min_quantity, currentOutputDetail.max_quantity)}
                                        disabled={isSubmitting}
                                        isAllowed={(values) => {
                                            const { floatValue, value } = values
                                            if (value === "")
                                                return true
                                            return (floatValue >= currentOutputDetail.min_quantity)
                                        }}
                                    />
                                    <Typography variant="h6">{currentOutputDetail.uom}</Typography>
                                </Stack>
                            }
                            {currentOutput !== "" &&
                                <TCamera setPhotoDataUri={setPhotoDataUri} photoDataUri={photoDataUri} disabled={isSubmitting} />
                            }

                        </Stack>
                    }


                    {/* submit event */}
                    <Submit eventDetail={eventDetail} productInfo={productInfo}
                        location={location} existingInputs={selectedInput}
                        photoDataUri={photoDataUri} readyToSubmit={hasValidData}
                        reset={reset}
                        isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />

                </Stack>
            </TabPanel>
        </Box>
    )

}