import { Typography, Stack, Button, Box, CircularProgress } from "@mui/material";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { DataGrid } from "@mui/x-data-grid";
import utc from 'dayjs/plugin/utc';
import * as React from "react";
import "dayjs/locale/en-in";
import dayjs from "dayjs";

import { getProductClass } from "../../utils/Constants";
import { getStocks } from "../../utils/capture";
import AuthConsumer from "../../contexts/auth";

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.locale('en-in')


const columns = [
    { field: "id", headerName: "S.No", width: 60, hide: true, sortable: false },
    {
        field: "gtin",
        headerName: "Product",
        type: "string",
        headerAlign: "left",
        width: 200,
        renderCell: (params) => (
            <Stack alignContent="left">
                <div style={{ textAlign: "left" }}>
                    <Typography variant="caption">{params.value}</Typography>
                </div>
            </Stack>
        ),
    },
    {
        field: "available_quantity",
        headerName: "Availablity",
        type: "int",
        width: 100,
    },
    {
        field: "uom",
        headerName: "Unit",
        type: "string",
        width: 100,
    },
    {
        field: "created_on",
        headerName: "Created On",
        type: "string",
        width: 100,
    },
    {
        field: "last_update_on",
        headerName: "Update On",
        type: "string",
        width: 100,
    }
];


export function InventoryList({ pageSize }) {
    const { user, getToken } = AuthConsumer();
    const [searching, setSearching] = React.useState(false);
    const [results, setResults] = React.useState([]);


    const search = async () => {
        setSearching(true);
        const token = await getToken();
        const searchObj = {
            "owning_gln": user.profile.og[0],
            "is_available": true
        }
        const searchResults = await getStocks(token, searchObj)

        const temp = searchResults.map((item, idx) => {
            item["id"] = idx + 1
            item["gtin"] = getProductClass(item["gtin"]).entity
            item["created_on"] = dayjs(item["created_on"] * 1000).format("L")
            item["last_update_on"] = dayjs(item["last_update_on"] * 1000).format("L")
            return item
        })
        setResults(temp)
        setSearching(false);
    }

    React.useEffect(() => {
        search().then(() => { })
        // eslint-disable-next-line
    }, [])


    return (
        <Stack sx={{ m: 1, p: 1 }} spacing={2} alignItems="center">
            <Stack direction="row" spacing={2} >
                {searching ? <CircularProgress size={36} /> :
                    <Button variant="contained" onClick={() => search()}>
                        <Typography variant="body1">
                            Refresh
                        </Typography>
                    </Button>
                }
            </Stack>

            {(results.length > 0) ?
                <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={results}
                        columns={columns}
                        getRowHeight={() => "auto"}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: (pageSize !== undefined ? pageSize : 10),
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                    />
                </Box> : <Typography variant="body2">
                    No Results Found!
                </Typography>
            }
        </Stack>
    )
}