import { Stack, Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Box, Tabs, Tab } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import * as React from 'react';

import { TabPanel, a11yProps } from "../Common/TabPanel";
import { TCamera } from '../Common/TCamera';
import RecentEvents from './recent_events';
import { Submit } from './submit';


export function Commission({ location, eventDetail, onExit }) {
    const [outputs, setOutputs] = React.useState([]);
    const [currentOutput, setCurrentOutput] = React.useState("");
    const [currentOutputDetail, setCurrentOutputDetail] = React.useState({});
    const [photoDataUri, setPhotoDataUri] = React.useState("");
    const [quantity, setQuantity] = React.useState("");

    const [hasValidData, setHasValidData] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [productInfo, setProductInfo] = React.useState(false)


    React.useEffect(() => {
        setOutputs(eventDetail.outputs)
    }, [eventDetail])

    const updateOutputDetail = (output_id) => {
        for (let output of eventDetail.outputs) {
            if (output.gs1_id === output_id) {
                setCurrentOutputDetail(output);
                setQuantity(output.default_quantity);
                break;
            }
        }
    };

    const materialUITextFieldProps = {
        variant: "standard",
        inputProps: { style: { fontSize: 18, textAlign: "center" } }
    };

    const quantityHelper = (min, max) => {
        if (min === max) {
            return ""
        } else {
            return "enter a value between " + min + " - " + max
        }
    }

    React.useEffect(() => {
        const updateSubmission = () => {

            if (currentOutput !== "" && photoDataUri !== "" && quantity > 0) {
                const productObj = {
                    "instance_class": currentOutputDetail.instance_class,
                    "gtin": currentOutputDetail.gs1_id,
                    "inputs": "none",
                    "quantity": quantity,
                    "uom": currentOutputDetail.uom,
                }
                setProductInfo(productObj)
                setHasValidData(true)
            } else {
                setProductInfo(null)
                setHasValidData(false)
            }
        }
        updateSubmission()
    }, [photoDataUri, quantity, currentOutput, currentOutputDetail])


    const reset = () => {
        setCurrentOutput("");
        setPhotoDataUri("");
        setCurrentOutputDetail({});
    }

    const back = () => {
        onExit()
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Button variant="contained" size="large" sx={{ width: 100, p: 1, m: 1 }}
                onClick={() => back()} disabled={isSubmitting}>
                <Typography>
                    Back
                </Typography>
            </Button>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} centered aria-label="orders tabs">
                    <Tab label="Capture" {...a11yProps(0)} />
                    <Tab label="Recent Events" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={1}>
                <RecentEvents eventType={eventDetail.user_event} />
            </TabPanel>
            <TabPanel value={value} index={0}>
                {eventDetail.outputs &&
                    <Stack spacing={2} alignItems="center" >
                        {(outputs.length > 0) &&
                            <FormControl>
                                <InputLabel id="outputs-select-label">{eventDetail.output_label}</InputLabel>
                                <Select
                                    labelId="outputs-select-label"
                                    id="outputs-select"
                                    label={eventDetail.output_label}
                                    value={currentOutput}
                                    disabled={isSubmitting}
                                    onChange={(evt) => {
                                        setCurrentOutput(evt.target.value);
                                        setPhotoDataUri("");
                                        updateOutputDetail(evt.target.value)
                                    }}
                                    displayEmpty
                                    sx={{ width: 300 }}
                                >
                                    {outputs.map((item) => (
                                        <MenuItem key={item.gs1_id} value={item.gs1_id}>{item.entity}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        {currentOutput !== "" && <TCamera setPhotoDataUri={setPhotoDataUri} photoDataUri={photoDataUri} disabled={isSubmitting} />}

                        {(currentOutputDetail && currentOutputDetail.min_quantity < currentOutputDetail.max_quantity) &&
                            <Stack direction="row" spacing={3} alignItems="center">
                                <Typography variant="h6">Quantity: </Typography>
                                <NumericFormat value={quantity} allowNegative={false} decimalScale={0}
                                    onChange={(evt) => setQuantity(evt.target.value)}
                                    customInput={TextField} {...materialUITextFieldProps}
                                    helperText={quantityHelper(currentOutputDetail.min_quantity, currentOutputDetail.max_quantity)}
                                    disabled={isSubmitting}
                                    isAllowed={(values) => {
                                        const { floatValue, value } = values
                                        if (value === "")
                                            return true
                                        return (floatValue >= currentOutputDetail.min_quantity && floatValue <= currentOutputDetail.max_quantity)
                                    }}
                                />
                                <Typography variant="h6">{currentOutputDetail.uom}</Typography>
                            </Stack>
                        }
                        <Submit eventDetail={eventDetail} productInfo={productInfo}
                            location={location}
                            photoDataUri={photoDataUri} readyToSubmit={hasValidData}
                            reset={reset}
                            isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />

                    </Stack>}
            </TabPanel>
        </Box>



    )
}