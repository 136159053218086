import * as React from 'react';

import { EventConsumer } from '../../contexts/events';
import { EventRecording } from './event_recording';
import { EventsList } from './event_list';

export function Events() {
    const { eventRecording } = EventConsumer()

    if (eventRecording) {
        return <EventRecording />
    } else {
        return <EventsList />
    }
}