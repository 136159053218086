import * as React from 'react';
import { getProductClass } from '../../utils/Constants';
import { Stack, Typography } from '@mui/material';
import { grey, blue } from '@mui/material/colors';


export function ProductPreview({ product, selected, setSelected, disabled }) {
    const [productInfo, setProductInfo] = React.useState({})

    React.useEffect(() => {
        const updateProductInfo = async () => {
            const gtin = product.instance_id.split("-")[0]
            const productclass = getProductClass(gtin)
            const data = {
                ...product,
                ...productclass,
                "instance_id": product.instance_id,
            }
            // console.log(data);
            setProductInfo(data)
        }
        updateProductInfo()
    }, [product])

    const updateSelection = (newSelection) => {
        if (selected !== null && newSelection.instance_id === selected.instance_id) {
            setSelected(null)
        } else {
            // console.log("new selection", newSelection); 
            setSelected(newSelection)
        }
    }

    return (
        <Stack
            sx={{ 
                border: "1px solid", m: 1, p: 1, 
                borderColor: grey[300], 
                background: (selected !== null && selected.instance_id === productInfo.instance_id) ? blue[300] : grey[100] 
            }}
            spacing={0.5}
            onClick={() => {
                if(!disabled) { 
                    updateSelection(productInfo)
                }
            }}
        >
            <img src={productInfo.image_url} alt={productInfo.entity} loading="lazy" 
                style={{ width: "300px", height: "300px", objectFit: "contain" }}/>
            <Typography variant="body2"> {productInfo.entity}</Typography>
            <Typography variant="caption"> {productInfo.date_time} </Typography>
            {(productInfo.available_quantity && productInfo.available_quantity > 0) &&
                <Typography variant="caption">{productInfo.available_quantity} / {productInfo.initial_quantity} {productInfo.uom} remaining as of {productInfo.last_update_on}</Typography>
            }
        </Stack>
    )
}