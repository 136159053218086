import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';

import { allowedEvents } from '../../utils/Constants';
import { EventConsumer } from '../../contexts/events';
import AuthConsumer from '../../contexts/auth';

export function EventsList() {
    const { user } = AuthConsumer();
    const { setCurrentEvent, setEventRecording } = EventConsumer()
    const [userAllowedEvents, setUserAllowedEvents] = React.useState([])

    React.useEffect(() => {
        setUserAllowedEvents(allowedEvents(user))
        // eslint-disable-next-line
    }, [user])

    const recordEvent = (event_name) => {
        setEventRecording(true)
        setCurrentEvent(event_name)
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            p: 1,
            m: 1
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: 1,
                m: 1
            }}
            >
                {userAllowedEvents.map((item) =>
                    <Button variant="contained" size="large" sx={{ width: 300, p: 1, m: 1 }}
                        key={item} onClick={() => recordEvent(item)}>
                        <Typography>
                            {item}
                        </Typography>
                    </Button>
                )}
            </Box>
        </Box>
    )

}