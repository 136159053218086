import React from "react";

import useLocalStorage from "../hooks/useLocalStorage";

const eventContext = React.createContext();

function useEvent() {
    const localCurrentLocation = JSON.parse(localStorage.getItem("currentLocation")) || {};
    const [currentLocation, setCurrentLocation] = useLocalStorage("currentLocation", localCurrentLocation);

    const localCurrentEvent = JSON.parse(localStorage.getItem("currentEvent")) || "";
    const [currentEvent, setCurrentEvent] = useLocalStorage("currentEvent", localCurrentEvent);

    const localEventRecording = JSON.parse(localStorage.getItem("eventRecording")) || false;
    const [eventRecording, setEventRecording] = useLocalStorage("eventRecording", localEventRecording);


    return {
        currentEvent,
        setCurrentEvent,
        currentLocation,
        setCurrentLocation,
        eventRecording,
        setEventRecording
    }
}

export function EventProvider({ children }) {
    const event = useEvent();

    return (
        <eventContext.Provider value={event}>
            {children}
        </eventContext.Provider>
    );
}

export function EventConsumer() {
    return React.useContext(eventContext);
}