import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import * as React from "react";

import { TabPanel, a11yProps } from "../Common/TabPanel";
import { OrdersListing } from "./order_listing";
import { OrdersUpload } from "./orders_upload";
import OrdersPrint from "./order_print";

export default function Orders() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} centered aria-label="orders tabs">
                    <Tab label="Upload" {...a11yProps(0)} />
                    <Tab label="Search" {...a11yProps(1)} />
                    <Tab label="Print" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <OrdersUpload />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <OrdersListing />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <OrdersPrint />
            </TabPanel>
        </Box>
    )
}