import { events_master } from "./events_master";

export const landingUrl = "/events";

export const defaultLocale = "en-in";

export const pageLinks = [
    { name: "Events", link: "/events" },
    { name: "Orders", link: "/orders" },
    { name: "Inventory", link: "/inventory" },
    { name: "Transactions", link: "/transactions" },
    // { name: "Fulfillment", link: "/fulfillment" },
    // { name: "Order Details", link: "/orders", skipMenu: true }
]

const pageRoles = {
    "/events": new Set(["*"]),
    "/orders": new Set(["admin"]),
    "/inventory": new Set(["*"]),
    "/transactions": new Set(["*"]),
}

export const isPageAllowed = (user, link) => {
    if (pageRoles[link].has("*")) {
        return true
    }
    if (user !== null) {
        for (const role of user.profile.roles) {
            if (pageRoles[link].has(role)) {
                return true
            }
        }
    }
    return false
};

export const allowedEvents = (user) => {
    return events_master.events.filter((evt) => {
        if (evt.roles.indexOf("*") > -1) {
            return true
        } else {
            for (let r of evt.roles) {
                if (user.profile.roles.indexOf(r) > -1) {
                    return true
                }
            }
        }
        return false
    }).map(evt => { return evt.user_event });
};

export const getEvents = (user, event_name) => {
    const events = events_master.events.filter((evt) => {
        if (evt.user_event === event_name) {
            if (evt.roles.indexOf("*") > -1) {
                return true
            } else {
                for (let r of evt.roles) {
                    if (user.profile.roles.indexOf(r) > -1) {
                        return true
                    }
                }
            }
        }
        return false
    })
    if (events.length > 0)
        return events[0]
    else
        return undefined
};

export const getLocations = (locations) => {
    const location_set = new Set(locations)
    return events_master.locations.filter((location) => {
        return location_set.has(location.gln)
    })
}

export const getCurrentLocation = async () => {
    const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return { 
        "latitude": position.coords.latitude, 
        "longitude": position.coords.longitude 
    };
}

export const roundNum = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const getProductClass = (gs1_id) => {
    return events_master.products[gs1_id]
}