import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import * as React from "react";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { green, red } from '@mui/material/colors';
import AuthConsumer from "../../contexts/auth";
import { uploadOrderFile } from "../../utils/capture";


export function OrdersUpload() {
    const { user, getToken } = AuthConsumer();
    const [uploading, setUploading] = React.useState("");
    const [errorText, setErrorText] = React.useState("");

    const [fileData, setFileData] = React.useState("");

    const fileHandlerRef = React.useRef();

    const getBase64 = async (file) => {
        return new Promise(resolve => {
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load data
            reader.onload = () => {
                const baseURL = reader.result.split(",")[1];
                resolve(baseURL);
            };
        });
    }

    const handleFileSelect = async (evt) => {
        try {
            setErrorText("");
            const uploadedFile = evt.target.files[0];
            const base64Data = await getBase64(uploadedFile);
            setFileData(base64Data);
        } catch (err) {
            console.log(err)
            setErrorText("Invalid file");
            setFileData("");
            fileHandlerRef.current.value = "";
        }
    }

    const clearFile = () => {
        fileHandlerRef.current.value = "";
        setFileData("");
        setUploading("");
        setErrorText("");
    }

    const uploadFile = async () => {
        try {
            setUploading("uploading");
            const fileObj = {
                "order_file": fileData,
                "seller_gln": user.profile.og[0],
            }
            const token = await getToken();
            const resp = await uploadOrderFile(token, fileObj);
            console.log(resp);
            if (resp.error !== undefined) {
                setUploading("error");
            } else {
                setUploading("success");
            }
            
            
        } catch (err) {
            console.log(err)
            setUploading("error");
        }
    }

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center"
                sx={{ flexWrap: 'wrap', gap: 2 }}>
                <input ref={fileHandlerRef} type="file" name="file" disabled={(uploading !== "")}
                    onChange={(evt) => handleFileSelect(evt)} accept=".xls,.xlsx" />
                <Button variant="contained" disabled={(fileData === "" || uploading !== "")}
                    onClick={() => uploadFile()}>
                    <Typography variant="button">Upload</Typography>
                </Button>
                <Button variant="contained" onClick={() => clearFile()}>
                    <Typography variant="button">Clear</Typography>
                </Button>
            </Stack>
            {(errorText !== "") && <Typography variant="caption"> {errorText}</Typography>}
            <Stack alignItems="center" direction="column" spacing={2} sx={{ m: 1 }}>
                {(uploading === "uploading") && <LinearProgress sx={{ width: 300 }} />}
                {(uploading === "success") && 
                    <Typography variant="body1" >
                        Orders uploaded <CheckCircleRoundedIcon sx={{ color: green[500], pt:1 }} /> 
                    </Typography>
                }

                {(uploading === "error") && <>
                    <Typography variant="caption">
                        Ensure the orders file matches the template provided and try Again! 
                        <ErrorRoundedIcon sx={{ color: red[500] }} />
                    </Typography>
                </>}
            </Stack>
        </>)
}