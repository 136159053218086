import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography, Stack, Button, Box, LinearProgress, Alert, Collapse } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import * as React from "react";
import "dayjs/locale/en-in";
import dayjs from "dayjs";

import { getCurrentLocation, getProductClass } from "../../utils/Constants";
import { deductStocks, getOrders, getStocks, updateOrderStatus, endEvent, startEvent, addInputsToEvent } from "../../utils/capture";
import AuthConsumer from "../../contexts/auth";
import { OrdersToolBar } from "./order_toolbar";
import { LinearProgressWithLabel } from "./LinearProgressWithLabel";
import { toIsoString } from "../../utils/date";
// import { delay } from "../../utils/Api";


dayjs.extend(localizedFormat)

const columns = [
    { field: "id", headerName: "Order Id", width: 90 },
    {
        field: "orderStatus",
        headerName: "Status",
        sortable: false,
        width: 100,
    },
    {
        field: "paidDate",
        headerName: "Paid On",
        type: "string",
        width: 100,
    },
    {
        field: "lineItems",
        headerName: "Order Items",
        sortable: false,
        width: 200,
        renderCell: (params) => (
            <Stack alignContent="left">
                {params.value.map((item, idx) => (
                    <div key={idx} style={{
                        textAlign: "left",
                        borderBottom: ((idx < params.value.length - 1) ? "1px solid lightgray" : "none"),
                        textTransform: "capitalize"
                    }}>
                        <Typography variant="caption" >
                            {(item.toLowerCase())}
                        </Typography>
                    </div>
                ))}
            </Stack>
        ),
    },
    {
        field: "shippingAddress",
        headerName: "Customer",
        flex: 1,
        width: 300,
        align: "left",
        sortable: false,
        renderCell: (params) => (
            <Stack alignContent="left">
                <div style={{ textAlign: "left" }}>
                    <Typography variant="caption">
                        {params.value}
                    </Typography>
                </div>
            </Stack>
        ),
    },
    {
        field: "uploadedOn",
        headerName: "Uploaded On",
        type: "string",
        width: 100,
    },
];

export function OrdersListing({ orderStatus, allowSelection, enableFulfillment, isSubmitting, setIsSubmitting, location, eventDetail }) {
    const { user, getToken } = AuthConsumer();
    const localSearchStart = dayjs().startOf("day").subtract(1, "days").format("YYYY-MM-DD");
    const [searchStart, setSearchStart] = React.useState(localSearchStart);

    const localSearchEnd = dayjs().endOf("day").format("YYYY-MM-DD");
    const [searchEnd, setSearchEnd] = React.useState(localSearchEnd);

    // Data grid
    const apiRef = useGridApiRef();
    const [results, setResults] = React.useState([]);

    const [searching, setSearching] = React.useState(false);
    const [checkboxSelection, setCheckboxSelection] = React.useState(false);

    // Fulfillment
    const ordersRef = React.useRef();
    const [orderSelection, setOrderSelection] = React.useState([]);
    const [allowEstimation, setAllowEstimation] = React.useState(false);
    const [fulfillmentConfirmation, setFulfillmentConfirmation] = React.useState(false);
    const [confirmationType, setConfirmationType] = React.useState("success");

    const [progressVariant, setProgressVariant] = React.useState("indeterminate");
    const [progress, setProgress] = React.useState(0);
    const [progressTotal, setProgressTotal] = React.useState(0);
    const [progressCurrent, setProgressCurrent] = React.useState(0);
    const [progressMessage, setProgressMessage] = React.useState("");
    const [validationResults, setValidationResults] = React.useState(null)

    // Display checkbox in the data grid
    React.useEffect(() => {
        if (allowSelection) {
            setCheckboxSelection(true)
        } else {
            setCheckboxSelection(false)
        }
    }, [allowSelection])

    // Search orders
    const search = async () => {
        setSearching(true);
        const searchObj = {
            "search_start_date": searchStart,
            "search_end_date": searchEnd,
        }
        if (orderStatus !== undefined && orderStatus !== "") {
            searchObj["order_status"] = orderStatus
        }
        try {
            const token = await getToken();
            const searchResp = await getOrders(token, searchObj);
            const tmpResults = [];

            for (const order of searchResp) {
                let row = {
                    "id": order.orderId,
                    "paidDate": dayjs(order.order_details.paidDate).locale('en-in').format("L"),
                    "uploadedOn": dayjs(order.processedDate).locale('en-in').format("L"),
                    "orderStatus": order.order_details.orderStatus,
                    "shippingAddress": getAddress(order.order_details.shippingAddress),
                    "lineItems": []
                }
                for (const lineItem of order.order_line_items) {
                    try {
                        const productInfo = getProductClass(lineItem.productId);
                        row.lineItems.push(productInfo.entity + " - " + lineItem.quantity);
                    } catch (err) {
                        // ignoring sku that are not mangoes
                    }
                }
                tmpResults.push(row)
            }

            if (ordersRef !== undefined) {
                const orderLineItems = {};
                for (const order of searchResp) {
                    orderLineItems[order.orderId] = {}
                    for (const lineItem of order.order_line_items) {
                        orderLineItems[order.orderId][lineItem.productId] = lineItem.quantity;
                    }
                }
                ordersRef.current = orderLineItems;
            }
            setResults(tmpResults);
            setSearching(false);
        } catch (err) {
            console.log(err);
            setSearching(false);
        }
    }

    const getAddress = (v) => {
        const mobile = v.mobile ? v.mobile : ""
        return `${v.customer_name}, ${v.address_1}, ${v.city}, ${v.state} - ${v.pincode}.\n${mobile}`
    }

    // Fulfillment Estimation
    React.useEffect(() => {
        setAllowEstimation(orderSelection.length > 0);
        // setFulfillmentConfirmation(false);
    }, [orderSelection])

    const estimate = async () => {
        setProgressVariant("indeterminate");
        setIsSubmitting(true);
        setFulfillmentConfirmation(false);
        const res = await getEstimates();
        setValidationResults(res);
        apiRef.current.setRowSelectionModel(res.orders);
        setIsSubmitting(false);
        setFulfillmentConfirmation(true);
        setConfirmationType(res.allMatched ? "success" : "warning")
    }

    const getEstimates = async () => {
        const gtins = new Set()
        for (const orderId of orderSelection) {
            const lineItems = ordersRef.current[orderId]
            for (const key of Object.keys(lineItems)) {
                gtins.add(key)
            }
        }
        const stockSearchObj = {
            "owning_gln": user.profile.og[0],
            "is_available": true,
            "gtin": [...gtins].join(",")
        }
        const token = await getToken();
        const stockAvailability = await getStocks(token, stockSearchObj);

        const gtinStocks = {}

        for (const stock of stockAvailability) {
            stock["remaining"] = stock["available_quantity"]
            if (gtinStocks[stock.gtin] === undefined) {
                gtinStocks[stock.gtin] = { seek: 0, stocks: [stock] }
            } else {
                gtinStocks[stock.gtin].stocks.push(stock)
            }
        }
        const ordersToFulfill = []

        for (const orderId of orderSelection) {
            const lineItems = ordersRef.current[orderId]
            let canDeduct = true
            const changeSet = {}
            // gtin -> stockIdx -> deduction quantity
            for (const [gtin, quantity] of Object.entries(lineItems)) {
                if (gtinStocks[gtin] === undefined) {
                    canDeduct = false
                    break
                } else {
                    let remaining_quantity = quantity
                    changeSet[gtin] = { "expected": quantity, "deduction": {} }
                    do {
                        let stockIdx = gtinStocks[gtin].seek
                        if (gtinStocks[gtin].stocks[stockIdx].remaining >= remaining_quantity) {
                            changeSet[gtin]["deduction"][stockIdx] = remaining_quantity
                            gtinStocks[gtin].stocks[stockIdx].remaining -= remaining_quantity
                            remaining_quantity = 0
                        } else {
                            changeSet[gtin]["deduction"][stockIdx] = gtinStocks[gtin].stocks[stockIdx].remaining
                            remaining_quantity = remaining_quantity - gtinStocks[gtin].stocks[stockIdx].remaining
                            gtinStocks[gtin].stocks[stockIdx].remaining = 0
                            gtinStocks[gtin].seek += 1
                        }
                    } while (remaining_quantity > 0 && gtinStocks[gtin].seek < gtinStocks[gtin].stocks.length)
                    if (remaining_quantity > 0) {
                        canDeduct = false
                        break
                    }
                }
            }
            if (!canDeduct) {
                for (const [stockGTIN, stockChange] of Object.entries(changeSet)) {
                    for (const [stockIdx, deductedValue] of Object.entries(stockChange.deduction)) {
                        if (gtinStocks[stockGTIN] !== undefined) {
                            gtinStocks[stockGTIN].stocks[stockIdx].remaining += deductedValue
                        }
                    }
                }
            } else {
                ordersToFulfill[orderId] = changeSet
            }
        }
        // console.log(ordersToFulfill)
        // console.log(gtinStocks)
        const orderIds = Object.keys(ordersToFulfill)
        const selected = orderSelection.length
        const matched = orderIds.length
        const allMatched = (orderSelection.length === orderIds.length)

        return {
            allMatched: allMatched,
            selected: selected,
            matched: matched,
            orders: orderIds,
            changeSet: ordersToFulfill,
            deduction: gtinStocks
        }
    }

    // Fulfill orders
    const fulfillOrders = async () => {
        // console.log("fulfilling orders");
        if (validationResults.matched <= 0) {
            return
        }
        setFulfillmentConfirmation(false);
        setIsSubmitting(true);
        setProgressVariant("determinate");
        setProgressMessage("Preparing!");
        setProgress(0);
        setProgressCurrent(0);
        setProgressTotal(0);
        setProgressTotal(validationResults.orders.length);
        const gtinStocks = validationResults.deduction;
        const position = await getCurrentLocation();
        let i = 0
        while (i < validationResults.orders.length) {
            const orderId = validationResults.orders[i];
            // console.log(orderId);
            // console.log(validationResults.changeSet[orderId]);
            const inputs = new Set();
            const fulfillmentDetails = []
            const changeSet = validationResults.changeSet[orderId];
            for (const [stockGTIN, stockChange] of Object.entries(changeSet)) {
                for (const [stockIdx, deductedValue] of Object.entries(stockChange.deduction)) {
                    if (gtinStocks[stockGTIN] !== undefined) {
                        console.log(gtinStocks[stockGTIN].stocks[stockIdx], deductedValue);
                        inputs.add(gtinStocks[stockGTIN].stocks[stockIdx].instance_id);
                        fulfillmentDetails.push({
                            gtin: stockGTIN,
                            instance_id: gtinStocks[stockGTIN].stocks[stockIdx].instance_id,
                            quantity: deductedValue,
                            uom: gtinStocks[stockGTIN].stocks[stockIdx].uom

                        })
                    }
                }
            }
            setProgressMessage("Fulfilling order " + orderId);
            setProgressCurrent(i)
            setProgress(Math.round(i * 100 / validationResults.orders.length));
            // console.log(inputs, fulfillmentDetails);
            // console.log("waiting")
            // await delay(2000);
            // i++;
            if (await submitOneOrder(orderId, inputs, fulfillmentDetails, position)) {
                i++;
            } else {
                break;
            }
        }
        if (i === validationResults.orders.length) {
            setProgressMessage("Completed!");
            setProgress(100);
            setProgressCurrent(i);
            setTimeout(async () => {
                setProgressMessage("");
                await search();
                setIsSubmitting(false);
            }, 1500);

        } else {
            setProgressMessage("Error Occurred while fulfiling orders! Try Again");
        }

    }

    const submitOneOrder = async (orderId, inputs, fulfillmentDetails, position) => {
        // 1. open fulfillment event as object delete with biz_transaction_id = order id
        // 2. Add inputs using the instance ids
        // 3. Deduct stocks
        // 4. Add order status [gtin, deductedValue, instance_id] fulfillment event id
        // 5. close fulfillment event
        const authToken = await getToken();
        const now = new Date();
        const epoch_now = Math.floor(now.getTime() / 1000)
        const iso_now = toIsoString(now);
        try {
            // 1. create event
            const eventObj = {
                ...position,
                "epcis_event": eventDetail.epcis_event,
                "event_access": eventDetail.event_access,
                "user_event": eventDetail.user_event,
                "epcis_action": eventDetail.epcis_action,
                "biz_step": eventDetail.biz_step,
                "biz_transaction_type": eventDetail.user_event,
                "biz_transaction_id": orderId,
                "event_start_datetime": iso_now,
                "created_by": user.email,
                "owning_gln": user.profile.og[0],
                "location": location.gln,
            }
            let event = await startEvent(authToken, eventObj);

            // 2. add inputs
            event = await addInputsToEvent(authToken, event.event_id, {
                "input_product_instances": [...inputs].join(",")
            });


            // 3. deduct stocks
            let stockIdx = 0;
            while (stockIdx < fulfillmentDetails.length) {
                const stockObj = {
                    "instance_id": fulfillmentDetails[stockIdx].instance_id,
                    "event_id": event.event_id,
                    "stock_quantity": fulfillmentDetails[stockIdx].quantity,
                    "created_at": iso_now,
                    "gtin": fulfillmentDetails[stockIdx].gtin,
                    "owning_gln": user.profile.og[0],
                    "location_gln": location.gln,
                    "uom": fulfillmentDetails[stockIdx].uom
                };
                await deductStocks(authToken, stockObj);
                stockIdx++;
            };

            // 4. order status
            const orderObj = {
                "order_status": "Fulfilled",
                "fulfillment_unix_epoch": epoch_now,
                "fulfillmentDetails": JSON.stringify({
                    "event": event.event_id,
                    "items": fulfillmentDetails
                })
            };
            await updateOrderStatus(authToken, orderId, orderObj);

            // 5. close event
            const end_now = new Date();
            const iso_end_now = toIsoString(end_now);
            const endEventObj = {
                "event_end_datetime": iso_end_now
            };
            await endEvent(authToken, event.event_id, endEventObj);
            return true;
        } catch (err) {
            console.log(err)
            return false;
        }
    }

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center"
                sx={{ flexWrap: "wrap", gap: 2 }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <DatePicker label={"From"}
                        value={dayjs(searchStart)}
                        onChange={(newValue) => setSearchStart(newValue.format("YYYY-MM-DD"))}

                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
                    <DatePicker label={"To"}
                        value={dayjs(searchEnd)}
                        onChange={(newValue) => setSearchEnd(newValue.format("YYYY-MM-DD"))}

                    />
                </LocalizationProvider>
                <Button variant="contained" size="large" onClick={() => search()}>
                    <Typography>
                        Search
                    </Typography>
                </Button>
            </Stack>
            <Stack alignItems={"center"} sx={{ m: 1 }}>
                {searching && <LinearProgress sx={{ width: 300 }} />}
            </Stack>
            {(results.length > 0) ?
                <Box >
                    <DataGrid
                        sx={{ height: 500 }}
                        apiRef={apiRef}
                        rows={results}
                        columns={columns}
                        getRowHeight={() => "auto"}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setOrderSelection(newRowSelectionModel);
                        }}
                        orderSelection={orderSelection}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        checkboxSelection={checkboxSelection}
                        disableRowSelectionOnClick
                        slots={{ toolbar: OrdersToolBar }}
                    />
                    {enableFulfillment &&
                        <Stack spacing={2} direction="column" alignItems="center" sx={{ m: 2 }}>
                            <Stack spacing={2} direction="row" alignItems="center" >
                                <Typography>Select Orders to </Typography>
                                <Button disabled={!allowEstimation && !isSubmitting} variant="contained"
                                    onClick={() => estimate()}>
                                    Validate fulfillment
                                </Button>
                            </Stack>

                            <Box sx={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
                                <Collapse in={isSubmitting}  >
                                    <LinearProgressWithLabel variant={progressVariant} value={progress} message={progressMessage}
                                        total={progressTotal} current={progressCurrent} />
                                </Collapse>
                                <Collapse in={(fulfillmentConfirmation && validationResults !== null)}>
                                    <Alert severity={confirmationType} action={
                                        <Stack direction="row" spacing={2}>
                                            <Button variant="contained" disabled={(validationResults !== null && validationResults.matched === 0)}
                                                onClick={() => { fulfillOrders() }}>
                                                Proceed
                                            </Button>
                                            <Button variant="contained"
                                                onClick={() => { setFulfillmentConfirmation(false) }}>
                                                Cancel
                                            </Button>
                                        </Stack>

                                    } sx={{ textAlign: "left" }}>
                                        Selected orders are validated against available inventory. <br />
                                        {(validationResults !== null) && <>{validationResults.matched} / {validationResults.selected}</>} orders can be fulfilment!
                                    </Alert>
                                </Collapse>
                            </Box>



                        </Stack>
                    }
                </Box> : <Typography variant="body2">
                    No Results Found!
                </Typography>
            }
        </>
    )


}