import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

export function Confirmation({ open, confirmationData, reset }) {


    const handleClose = () => {
        reset();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle >
                {confirmationData.user_event}
            </DialogTitle>
            <DialogContent>
                <Stack >
                    <Typography variant="body1">
                        {confirmationData.event_start_datetime} {confirmationData.event_timezone_offset}
                    </Typography>
                    <Typography variant="body1">{confirmationData.location_name}</Typography>

                    {confirmationData.entity &&
                        <Typography variant="body1">
                            {confirmationData.entity} ({confirmationData.gs1_id})
                        </Typography>
                    }
                    {confirmationData.img_url !== "" && <img src={confirmationData.img_url} alt="preview"
                        style={{ width: "100%", borderRadius: "2%", border: "3px solid gray" }} />
                    }
                    <Typography variant="caption">Event ID: {confirmationData.event_id}</Typography>
                    <Typography variant="caption">Product Instance Id: {confirmationData.instance_id}</Typography>
                    {confirmationData.instance_class &&
                        <Typography variant="caption">Product Instance Class: {confirmationData.instance_class}</Typography>
                    }
                    {confirmationData.quantity &&
                        <Typography variant="caption">Quantity: {confirmationData.quantity} {confirmationData.uom}</Typography>
                    }
                    <Button onClick={() => handleClose()}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )

}