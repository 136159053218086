import Camera, { IMAGE_TYPES, FACING_MODES } from "react-html5-camera-photo";
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { Box, Button, Typography, Stack } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import "react-html5-camera-photo/build/css/index.css";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export function TCamera({ photoDataUri, setPhotoDataUri, disabled }) {
    const [openCamera, setOpenCamera] = useState(false);
    const [idealFacingMode, setIdealFacingMode] = useState(FACING_MODES.ENVIRONMENT);
    const isFullscreen = false;
    const rowsx = {
        display: "flex",
        justifyContent: "center",
        bgcolor: "background.paper",
    }
    const handleTakePhotoAnimationDone = (dataUri) => {
        setPhotoDataUri(dataUri);
        setOpenCamera(false);
    }

    function cancel() {
        setPhotoDataUri("");
        setOpenCamera(false);
    }

    function toggleCameraMode() {
        setIdealFacingMode(idealFacingMode === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT);
        console.log("idealFacingMode : " + idealFacingMode);
    }


    function open() {
        setPhotoDataUri("");
        setOpenCamera(true);
    }

    return (

        <Stack direction="column" spacing={3} alignItems="center">
            
            {(openCamera === false && photoDataUri !== "") &&
                <img src={photoDataUri} alt="preview" style={{  width: "100%", borderRadius: "2%", border: "3px solid gray" }} />}
            {openCamera === true && (
                <>
                    <Camera
                        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                        isFullscreen={isFullscreen}
                        imageType={IMAGE_TYPES.JPG}
                        idealFacingMode={idealFacingMode}
                        imageCompression={0.8}
                        idealResolution = {{width: 1080, height: 720}}
                        isImageMirror={idealFacingMode === FACING_MODES.ENVIRONMENT ? false : true}
                    />

                    <Box sx={rowsx}>
                        <CloseIcon onClick={cancel} sx={{ p: 1, m: 1 }} />
                        <CameraswitchIcon onClick={toggleCameraMode} sx={{ p: 1, m: 1 }} />
                    </Box>
                </>
            )}
            {openCamera === false &&
                <Button variant="contained" size="medium" sx={{ width: 300 }} onClick={open} disabled={disabled}>
                    <Typography gutterBottom sx={{ m: 1 }}>
                        {photoDataUri === "" ? (<>Take a</>) : (<>Retake</>)} Photo
                    </Typography>
                    <AddAPhotoIcon data-testid="AddAPhotoIcon" sx={{ m: 1 }} />
                </Button>
            }
        </Stack>
    )
}