import pRetry from "p-retry";


const run = async (url, options) => {
    const response = await fetch(url, options);
    return response.json();
}

const fetchRetry = async (url, options) => {
    return await pRetry(() => run(url, options), { 
        retries: 5,
        onFailedAttempt: error => {
            console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
            // 1st request => Attempt 1 failed. There are 4 retries left.
            // 2nd request => Attempt 2 failed. There are 3 retries left.
            // …
        },
    });
}


// Start event
export const startEvent = async (authToken, eventObj) => {
    const formData = new FormData();
    Object.keys(eventObj).forEach(key => formData.append(key, eventObj[key]));

    const url = process.env.REACT_APP_AWS_EVENTS_API + "/events";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    }
    return await fetchRetry(url, options);

}

// Add Inputs
export const addInputsToEvent = async (authToken, event_id, inputs) => {
    const formData = new FormData();
    Object.keys(inputs).forEach(key => formData.append(key, inputs[key]));

    const url = process.env.REACT_APP_AWS_EVENTS_API + "/events/" + event_id + "/inputs";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}


// Add Outputs
export const addOutputsToEvent = async (authToken, event_id, outputs) => {
    const formData = new FormData();
    Object.keys(outputs).forEach(key => formData.append(key, outputs[key]));

    const url = process.env.REACT_APP_AWS_EVENTS_API + "/events/" + event_id + "/outputs";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    }
    return await fetchRetry(url, options);
}


// End event

export const endEvent = async (authToken, event_id, eventObj) => {
    const url = process.env.REACT_APP_AWS_EVENTS_API + "/events/" + event_id + "/status";
    const options = {
        method: "PUT",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(eventObj),
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}

// search events
export const searchEvents = async (authToken, searchObj) => {
    const params = new URLSearchParams(searchObj)
    const url = process.env.REACT_APP_AWS_EVENTS_API + "/events?" + params ;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken,
            "Content-Type": "application/json"
        },
    };
    return await fetchRetry(url, options);
}

// create product instance
export const createProductInstance = async (authToken, productObj) => {
    const formData = new FormData();
    Object.keys(productObj).forEach(key => formData.append(key, productObj[key]));

    const url = process.env.REACT_APP_AWS_PRODUCTS_API + "/product_instances";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}

// get product instance
export const getProductInstanceById = async (authToken, searchObj) => {
    const params = new URLSearchParams(searchObj);
    const url = process.env.REACT_APP_AWS_PRODUCTS_API + "/product_instances/ids?" + params;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        }
    };
    return await fetchRetry(url, options);
}

// get GTIN info


// add image
export const uploadImage = async (authToken, imageObj) => {
    const formData = new FormData();
    Object.keys(imageObj).forEach(key => formData.append(key, imageObj[key]));

    const url = process.env.REACT_APP_AWS_IMAGES_API + "/images";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}

// get images
export const getImages = async (authToken, searchObj) => {
    const params = new URLSearchParams(searchObj);
    const url = process.env.REACT_APP_AWS_IMAGES_API + "/images?" + params;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        }
    };
    return await fetchRetry(url, options);
}


// Orders

// POST orders
export const uploadOrderFile = async (authToken, fileObj) => {
    const formData = new FormData();
    Object.keys(fileObj).forEach(key => formData.append(key, fileObj[key]));

    const url = process.env.REACT_APP_AWS_ORDERS_API + "/orders";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}

// GET orders
export const getOrders = async (authToken, searchObj) => {
    const params = new URLSearchParams(searchObj);
    const url = process.env.REACT_APP_AWS_ORDERS_API + "/orders?" + params;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        }
    };
    return await fetchRetry(url, options);
}

// Update order status

export const updateOrderStatus = async (authToken, order_id, orderObj) => {
    const url = process.env.REACT_APP_AWS_ORDERS_API + "/orders/" + order_id + "/status";
    const options = {
        method: "PUT",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(orderObj),
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}


// Get Stocks
export const getStocks = async (authToken, searchObj) => {
    const params = new URLSearchParams(searchObj);
    const url = process.env.REACT_APP_AWS_PRODUCTS_API + "/stocks?" + params;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        }
    };
    return await fetchRetry(url, options);
}

// Get Stocks By User Events
export const getStocksByUserEvents = async (authToken, searchObj) => {
    const params = new URLSearchParams(searchObj);
    const url = process.env.REACT_APP_AWS_PRODUCTS_API + "/user_event_stocks?" + params;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        }
    };
    return await fetchRetry(url, options);
}

// deduct stocks
export const deductStocks = async (authToken, stockObj) => {
    const formData = new FormData();
    Object.keys(stockObj).forEach(key => formData.append(key, stockObj[key]));

    const url = process.env.REACT_APP_AWS_PRODUCTS_API + "/stocks";
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Authorization": "Bearer " + authToken
        },
        body: formData,
        redirect: "follow"
    };
    return await fetchRetry(url, options);
}
